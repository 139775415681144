import React from 'react';
import CardError from '../component/card/error.jsx';

import image500 from '../artifacts/500.jpg';

export default function PageServerError({ error, state }) {
    return (
        <div className="page">
            <CardError
                state={state.root}
                title="Oof!"
                info="We're so sorry, but something went very wrong"
                error={error}
                image={<img src={image500} alt="Surprised pikachu face (Server error)" />}
            />
        </div>
    );
}
