import React from 'react';
export default function PageStaticPrivacy({ state }) {
    const mailTo = `mailto:${state.root.email.$contact}`;
    return (
        <div className="page">
            <h1>Privacy Policy</h1>
            <h3>WHO WE ARE</h3>
            <p>
                Extasis Group operates this website and is the controller of the information
                collected or provided via this Website.
            </p>

            <h3>IMPORTANT NOTICE REGARDING ADULT CONTENT</h3>
            <p>
                This is an adult website that expressly and strictly limits its membership to
                adults. We do not knowingly seek or collect any personal information or data from
                persons under the age of 18. All persons under the age of 18 are strictly prohibited
                from accessing or viewing the contents on this website. If you are under the age of
                18 and/or under the age of majority in the jurisdiction you reside and from which
                you access the website, you must leave this site immediately, and your account can
                be terminated without notice.
            </p>

            <h3>WHAT DATA WE COLLECT</h3>
            <p>
                In order to provide our service when you subscribe we collect the following data
                from you:
                <br />
                <br />
                - Your email address, username and password so you can log in to the website.
                <br />
                - Your IP address to determine your general location and protect your account from
                being hacked.
                <br />
                - Your name, country, ZIP or post code, credit card or other billing information,
                through a third party billing provider (as listed below), so we can charge you the
                subscription fees.
                <br />
            </p>

            <h3>WHO WE SHARE YOUR DATA WITH</h3>
            <p>
                We only ever share your personal data with other companies if necessary for the
                operation of our websites. These other companies are legally required to have the
                same data security standards we do. Below you can find a complete list of who we
                might share your data with. Note that we may change our partners in the future, in
                which case this document will be updated.
            </p>
            <p>
                If you sign up to one or more of our websites, your credit card or other billing
                data is processed by one of our payment processor,{` `}
                <a href="http://verotel.com">verotel.com</a>
            </p>
            <p>
                You can tell which of these companies processes your data from both the page you see
                when you pay for your subscription, as well as from the billing related emails you
                receive.
            </p>
            <p>We do not store your payment information in our internal systems.</p>

            <h3>USE OF COOKIES</h3>
            <p>
                We use certain tools to collect information about users browsing our website. These
                tools use cookies which are small text files that are stored on a user’s computer
                hard drive. The cookies are used, among other things, for fraud reduction or for
                security purposes. We also use cookies to recognize customers or a previous visitor
                of our website to pre-populate website forms. Remember, though, without cookies,
                users may not be able to take full advantage of all of our website features.
            </p>

            <h3>ANALYTICS</h3>
            <p>
                We may use third-party Service Providers to monitor and analyze the use of our
                Service. You can opt-out of having made your activity on the Service available to
                Google Analytics by installing the Google Analytics opt-out browser add-on. The
                add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js)
                from sharing information with Google Analytics about visits activity. For more
                information on the privacy practices of Google, please visit the Google Privacy &
                Terms web page:
                <a href="http://www.google.com/intl/en/policies/privacy/">
                    http://www.google.com/intl/en/policies/privacy/
                </a>
            </p>

            <h3>HOW LONG WE STORE YOUR DATA</h3>
            <p>
                Unless you ask to have it deleted, we will store your data indefinitely in order to
                give you continued access to any content that was present at the time your
                subscription ended.
            </p>

            <h3>ADVERTISING AND OFFERS</h3>
            <p>
                If you click on any advertising or special offers on this website you may be
                directed to a third party website that is outside our control, and in some cases, we
                might share some data with those websites in order to make things easier for you. In
                these cases, you are responsible for making sure that these websites keep your data
                as safe as we do.
            </p>

            <h3>SPECIAL PROVISIONS FOR USERS COVERED BY THE GDPR</h3>
            <p>
                Please note that, as stated above, your data will be stored on our partners’ servers
                in the USA.
            </p>

            <h3>WHICH COUNTRIES ARE COVERED BY THE GDPR</h3>
            <p>
                We apply GDPR guidelines for all users in the EU, EEA, the microstates of Andorra,
                Monaco and San Marino, as well as Switzerland.
            </p>

            <h3>YOUR DATA RIGHTS</h3>
            <p>
                You have the right to access or rectify the personally identifiable data we hold
                about you.
            </p>
            <p>
                You also have the right to ask for your data to be deleted. Just keep in mind that
                we might need it in order to fulfil the rest of your subscription
            </p>
            <p>
                If you want to access, rectify or delete your data, please send us an email{` `}
                <a href={mailTo}>here</a>
            </p>

            <h3>COMPLAINTS</h3>
            <p>
                In the unfortunate case where you are not happy with our privacy policy or processes
                you can send us an email <a href={mailTo}>here</a>.
            </p>
        </div>
    );
}
