import assert from 'assert';
import { useState, useEffect } from 'react';

export default function useObservable(observable, intialState) {
    assert(observable, `observable MUST be supplied`);

    const shouldWatch = typeof observable !== `function`;
    const watch = shouldWatch ? [observable] : [];

    const [state, setState] = useState(intialState);
    useEffect(() => {
        const obs = typeof observable === `function` ? observable() : observable;

        const sub = obs.subscribe(value => {
            setState(value);
        });
        return () => sub.unsubscribe();
    }, watch);
    return state;
}
