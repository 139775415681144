import createAssetService from '../../argonath/asset.js';
import createTalentService from '../../argonath/talent.js';
import promiseTree from '../../../lib/promise-tree.js';
import placeHolder from '../../../lib/placeholder.js';
import mutex from '../mutex.js';
import createState from '../state.js';

const LIMIT = 15;

const NAMES = (
    `ANGEL BELLINI KREY BAILEY BLOSSOM TAYLOR HAZEL ` +
    `JASMINE JEWELZ JILL QUINN MACE MEADOWS KALISY DEVI EXICO ` +
    `ROSE SU TRISTAN`
).split(` `);

const LOADING_MODELS_MODEL = {
    modelId: `model-loading`,
    name: ``,
    loading: true,
    image: {
        portraight: {
            x1: placeHolder(8, 9),
        },
        landscape: {
            x1: placeHolder(16, 9),
        },
    },
};

const DEFAULT = [
    createLoadingModel(0),
    createLoadingModel(1),
    createLoadingModel(2),
    createLoadingModel(3),
    createLoadingModel(4),
    createLoadingModel(5),
    createLoadingModel(6),
    createLoadingModel(7),
    createLoadingModel(8),
];

export default function createModelsState({ root, values: { models = DEFAULT } = {} }) {
    const prepareModel = createModelPreparer(root);
    const { list: listTalent } = createTalentService(root);

    const state = createState({
        root,

        values: {
            $page: `models`,
            title: `Models`,
            models,
        },

        createActions,
    });

    return state;

    function createActions() {
        return {
            load,
            next: mutex(next),
        };

        async function load() {
            if (state.models !== DEFAULT) {
                return;
            }
            const models = await loadFrom(undefined);
            state.models = models;
        }

        async function next() {
            // We don't want next to be available until after
            //  the initial load is complete
            if (state.models === DEFAULT) {
                return;
            }

            const lastModel = state.models[state.models.length - 1];
            if (!lastModel || lastModel.isLast) {
                return;
            }

            const after = lastModel && lastModel.cursor;
            const models = await loadFrom(after);

            if (models.length === 0 && state.models.length > 0) {
                state.models[state.models.length - 1].isLast = true;
            }

            state.models = [...state.models, ...models].filter(unique);
        }

        async function loadFrom(after) {
            const items = await listTalent(
                undefined, // order
                undefined, // desc
                after, // after
                LIMIT // count
            );
            const models = await Promise.all(items.map(talent => prepareModel(talent)));
            return models;
        }

        function unique(ele, idx, arr) {
            const idIndex = arr.findIndex(item => item.modelId === ele.modelId);
            return idx === idIndex;
        }
    }
}

export function createModelPreparer(state) {
    const { serve } = createAssetService(state);
    return function prepareModel(talent) {
        if (!talent) {
            return talent;
        }
        const { talentId, videos, ...rest } = talent;
        return promiseTree({
            modelId: talentId,
            ...rest,
            image: {
                portraight: {
                    x1: serveImage(`/members/models/${talentId}/profile-sm.jpg`),
                },
                landscape: {
                    x1: serveImage(`/members/models/${talentId}/profile-big.jpg`),
                },
            },
            videos:
                videos &&
                videos.map(video => {
                    const [, sceneId] = video.videoId.split(`:`);
                    return {
                        ...video,
                        image: {
                            portraight: {
                                x1: serveImage(
                                    `/members/models/${talentId}/scenes/${sceneId}/selfie-sm.jpg`
                                ),
                            },
                            landscape: {
                                x1: serveImage(
                                    `/members/models/${talentId}/scenes/${sceneId}/videothumb.jpg`
                                ),
                            },
                        },
                    };
                }),
        });
    };

    async function serveImage(path) {
        if (!path) {
            return undefined;
        }
        const info = await serve(path);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return undefined;
        }
        return info && info.serve && info.serve.uri;
    }
}

function createLoadingModel(index) {
    return {
        ...LOADING_MODELS_MODEL,
        modelId: `${LOADING_MODELS_MODEL.modelId}-${index}`,
        name: randomName(),
    };

    function randomName() {
        const first = NAMES[Math.floor(Math.random() * NAMES.length)];
        const last = NAMES[Math.floor(Math.random() * NAMES.length)];
        return `${first} ${last}`;
    }
}
