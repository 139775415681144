import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CardImageTitled from '../component/card/image-titled.jsx';
import Navigate from '../container/navigate.jsx';
import { Spinner } from '../component/element/spinner.jsx';
import Cards from '../container/cards.jsx';

const MAX_IMAGES = 5;
const LOAD_PADDING = 800;

const StyledPage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const Title = styled.h1`
    font-size: 1.5em;
    text-align: center;
    text-transform: uppercase;
`;

export default function PageVideos({ state }) {
    const videos = state.useVideos();
    const contentRef = useRef(null);

    useEffect(() => {
        document.addEventListener(`scroll`, onScroll);
        return () => document.removeEventListener(`scroll`, onScroll);

        function onScroll() {
            if (hasReachedBottom(contentRef)) {
                state.next();
            }
        }
    }, [videos]);

    return (
        <StyledPage className="page" ref={contentRef}>
            <Title className="heading">All Videos</Title>
            <Cards>
                {videos.map((video, index) => (
                    <Navigate
                        state={state.root}
                        key={video.videoId}
                        href={`/videos/${video.videoId}`}
                        disabled={video.loading}
                    >
                        <CardImageTitled
                            title={video.title}
                            image={video.image.landscape}
                            blurTitle={video.loading}
                            lazy={index >= MAX_IMAGES}
                        />
                    </Navigate>
                ))}
            </Cards>
            {videos[videos.length - 1]?.isLast ? null : <Spinner />}
        </StyledPage>
    );

    function hasReachedBottom(ref) {
        if (ref.current) {
            const bounds = ref.current.getBoundingClientRect();
            const threshold = window.innerHeight + LOAD_PADDING;
            const reached = bounds.bottom <= threshold;
            return reached;
        } else {
            return false;
        }
    }
}
