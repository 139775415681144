import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Header from './header.jsx';
import Footer from './footer.jsx';
import 'site/site.css';
import 'style/app.css';
import { setTracking } from '../lib/tracking.js';
import { del, getData, setData } from '../lib/storage.js';
import LoginDialog from '../component/dialog/login.jsx';
import LockdownDialog from '../component/dialog/lockdown.jsx';
import ResetPasswordDialog from '../component/dialog/reset-password.jsx';
import BusyOverlay from '../component/element/busy-overlay.jsx';
import { Discounts } from './discounts.jsx';

import PageWebmasters from 'site/webmasters.jsx';
import PageServerError from '../page/500.jsx';
import PageNotFound from '../page/404.jsx';
import PageHome from '../page/home.jsx';
import PageJoin from '../page/join.jsx';
import PageModels from '../page/models.jsx';
import PageModel from '../page/model.jsx';
import PageVideos from '../page/videos.jsx';
import PageVideo from '../page/video.jsx';
import PageTour from '../page/tour.jsx';
import PageWelcome from '../page/welcome.jsx';
// import PageLinkedLogin from '../page/linked-login.jsx';
import PagePasswordReset from '../page/password-reset.jsx';
import PageUsc2257 from '../page/static/usc2257.jsx';
import PagePrivacy from '../page/static/privacy.jsx';
import PageTerms from '../page/static/terms.jsx';
import PageSearchResults from '../page/search-results.jsx';

const HIDE_MENU = [`join`];

const StyledPage = styled.div`
    display: flex;
    flex-direction: column;

    .spacer {
        flex-grow: 1;
    }
    header,
    main,
    footer {
        flex-grow: 0;
    }
`;

export default function App({ state, error }) {
    // Make sure we save any tracking querystring values
    setTracking();
    const [user, setUserState] = useState(undefined);
    function setUser(u) {
        if (u) {
            setData(`user`, u);
            setUserState(u);
        } else {
            del(`user`);
            setUserState(undefined);
        }
    }
    useEffect(() => {
        const storedUser = getData(`user`);
        storedUser &&
            !user &&
            fetch(`${state.root.key.api}/api/legacy/user/refresh?site=${state.root.$site}`, {
                method: `get`,
                credentials: `include`,
                headers: {
                    Accept: `application/json`,
                },
            }).then(response => response.ok && response.json().then(({ user: u }) => setUser(u)));
    });
    return (
        <>
            <Content user={user} setUser={setUser} state={state} error={error} />
            <LoginDialog setUser={setUser} state={state} />
            <ResetPasswordDialog state={state} />
        </>
    );
}

function Content({ user, setUser, state, error }) {
    const current = state.page.useCurrent();
    // TODO: Consider this.
    const packages = state.biller.usePackages();

    return (
        <>
            <StyledPage className="app">
                <Header user={user} state={state} hideMenu={HIDE_MENU.includes(current?.$page)} />
                <main>
                    <Page user={user} setUser={setUser} current={current} error={error} />
                    <Discounts
                        title="DISCOUNTS FROM OUR FRIENDS"
                        hide={
                            !user || HIDE_MENU.includes(current?.$page) || current?.$page === `home`
                        }
                    />
                </main>
                <div className="spacer" />
                <BusyOverlay state={state} />
                <Footer user={user} setUser={setUser} state={state} />
            </StyledPage>
            <LockdownDialog
                user={user}
                setUser={setUser}
                state={state}
                site={state.root.$site}
                packages={packages}
                activate={state.biller.activate}
                upgrade={state.biller.upgrade}
                reactivate={state.biller.reactivate}
            />
        </>
    );
}

function Page({ user, current, error, setUser }) {
    if (error) {
        return <PageServerError error={error} state={current} />;
    }
    const props = { state: current, user };
    switch (current?.$page) {
        case `home`:
            return <PageHome {...props} />;
        case `tour`:
            return <PageTour {...props} />;
        case `join`:
            return <PageJoin {...props} />;
        case `model`:
            return <PageModel {...props} />;
        case `models`:
            return <PageModels {...props} />;
        case `video`:
            return <PageVideo {...props} />;
        case `videos`:
            return <PageVideos {...props} />;
        case `webmasters`:
            return <PageWebmasters {...props} />;
        case `usc2257`:
            return <PageUsc2257 {...props} />;
        case `privacy`:
            return <PagePrivacy {...props} />;
        case `terms`:
            return <PageTerms {...props} />;
        case `welcome`:
            return <PageWelcome {...props} />;
        // case `linked-login`:
        //     return <PageLinkedLogin {...props} />;
        case `password-reset`:
            return <PagePasswordReset {...props} setUser={setUser} />;
        case `search-results`:
            return <PageSearchResults {...props} />;
        case `404`:
        default:
            return <PageNotFound {...props} />;
    }
}
