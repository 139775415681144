import React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.div`
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;

    div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: var(--color-spinner);
        animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }
    div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }
    div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes spinner {
        0% {
            top: 8px;
            height: 64px;
        }
        50%,
        100% {
            top: 24px;
            height: 32px;
        }
    }
`;

export function Spinner(props) {
    return (
        <StyledSpinner {...props}>
            <div></div>
            <div></div>
            <div></div>
        </StyledSpinner>
    );
}
