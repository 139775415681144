import React from 'react';
import styled from 'styled-components';
import Money from './money.jsx';
import constants from '../constants.js';

export default MembershipOption;

const StyledMembershipOption = styled.div`
    display: block;
    margin: 1em;

    input {
        display: block;
        margin-top: 0.25em;
        box-sizing: border-box;
        width: 100%;
        height: 2.4375em;
        box-shadow: inset 0 1px 2px rgba(var(--color-primary-text-rgb), 0.1);
        font-weight: normal;
        border: 1px solid rgba(var(--color-primary-text-rgb), 0.2);
        font-size: 1em;
        padding-left: 0.5em;
    }

    input[type='radio'] {
        display: none;
    }

    input[type='radio']:checked + label,
    label.option:hover {
        color: var(--color-action-text);
        background-color: var(--color-action-button);
    }

    input[type='radio']:checked + label > .description > span,
    label.option:hover > .description > span {
        color: var(--color-action-text);
    }

    input[type='radio']:checked + label > .price > .current {
        color: var(--color-action-text);
        background-color: var(--color-action);
    }

    .option {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        color: var(--color-action-button);
        border: 1px solid rgba(var(--color-border-dark-rgb), 0.2);
        margin-bottom: 1em;
        padding-left: 1em;
        line-height: 4em;
        background-color: var(--color-join-item);
        font-family: var(--font-secondary);
        font-weight: 600;
    }

    .option > .description > h2 {
        display: inline-block;
        margin: 0;
        margin-right: 0.5em;
        font-weight: 500;
        font-size: 1.8em;
    }

    .option > .description {
        flex-grow: 1;
    }

    .option > .description > span {
        font-family: var(--font-secondary);
        color: var(--color-secondary-text);
        font-weight: 200;
    }

    .option > .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 0;
    }

    .previous {
        position: relative;
        white-space: nowrap;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 1em;
        padding-right: 1em;
        width: 6em;
        text-align: center;
        font-family: var(--font-secondary);
        color: var(--color-action-text);
    }

    .previous:before {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -2em;
        border: 2em solid transparent;
        border-top: 2em solid var(--color-action);
        border-bottom: 2em solid var(--color-action);
        border-right: 0;
    }

    .previous:after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        border: 2em solid transparent;
        border-left: 2em solid var(--color-action);
    }

    .current {
        white-space: nowrap;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-left: 1em;
        padding-right: 1em;
        width: 5em;
        text-align: right;
        font-family: var(--font-secondary);
    }

    .current > .symbol {
        font-size: 1.5em;
    }

    .current > .large {
        font-size: 1.5em;
    }

    .current > .small {
        font-size: 0.75em;
    }

    .strike-through {
        text-decoration: line-through;
    }

    @media (max-width: 30em) {
        .sub {
            display: none;
        }

        .option > .description > h2 {
            font-size: 1.5em;
        }
    }

    @media (max-width: ${constants.size.small}) {
        .previous {
            display: none;
        }
    }
`;

function MembershipOption({
    code,
    default: defaultChecked,
    description,
    previous,
    currency,
    current,
}) {
    const PriceLarge = props => <span className="price large">{props.children}</span>;
    const PriceSmall = props => <span className="price small">.{props.children}</span>;

    return (
        <StyledMembershipOption key={code}>
            <input
                type="radio"
                name="package"
                value={code}
                id={code}
                defaultChecked={defaultChecked}
            />
            <label className="option" htmlFor={code}>
                <div className="description">
                    <h2>{description.main}</h2>
                    <span className="sub">{description.sub}</span>
                </div>
                <div className="price">
                    <span className="previous primary primary-text">
                        {previous && (
                            <span>
                                was&nbsp;
                                <span className="strike-through">
                                    <Money
                                        currency={currency}
                                        amount={previous}
                                        Large={props => <span>{props.children}</span>}
                                        Small={props => <span>.{props.children}</span>}
                                    />
                                </span>
                            </span>
                        )}
                    </span>
                    <span className="current">
                        <Money
                            currency={currency}
                            amount={current}
                            Large={PriceLarge}
                            Small={PriceSmall}
                        />
                    </span>
                </div>
            </label>
        </StyledMembershipOption>
    );
}
