import React from 'react';
import styled from 'styled-components';

const StyledOurStory = styled.div`
    h1 {
        text-align: center;
    }
`;

export default function OurStory({ state }) {
    const ourStory = state.useOurStory();

    return (
        <StyledOurStory>
            <h1>{ourStory.name}</h1>
            <div dangerouslySetInnerHTML={{ __html: ourStory.value }}></div>
        </StyledOurStory>
    );
}
