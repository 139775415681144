import assert from 'assert';
import { ApolloLink } from '@apollo/client';

export default function createStateLink(state) {
    assert(state, `state MUST be supplied`);
    return new ApolloLink((operation, next) => {
        operation.setContext(previous => ({
            ...previous,
            state: state.root,
        }));
        return next(operation);
    });
}
