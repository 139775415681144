export default function mutex(handler) {
    let executing;
    return function mutexed() {
        if (!executing) {
            executing = handler();
            if (executing && typeof executing.then === `function`) {
                executing.then(clearExecuting, clearExecuting);
            }
        }
        return executing;
    };

    function clearExecuting() {
        executing = undefined;
    }
}
