import React from 'react';
import styled from 'styled-components';

const StyledPage = styled.div`
    margin-left: auto;
    margin-right: auto;

    .callout {
        background-color: #f7e4e1;
        padding: 1em;
        border: 1px solid rgba(var(--color-primary-text-rgb), 0.25);
    }
`;

export default function PageWebmasters({ state }) {
    const site = state.root.$site.toUpperCase();
    const mailTo = `mailto:${state.root.email.$contact}`;
    return (
        <StyledPage className="page">
            <h1>{site} AFFILIATE PROGRAM</h1>
            <div className="callout">
                All webmasters registered prior to December 2019, please update your links by
                <a href="https://controlcenter.verotel.com/register-reseller?website=tl7ti6dhra9vlmwlhmpy18cbz9jf7tsmj7omoxxx">
                    clicking here
                </a>
                to add our new site code to your account.
                <br />
                <br />
                We recently added trials to the membership options, which{` `}
                <strong>increased sales by 30+%</strong>, but required the tracking to use new site
                codes from verotel.
            </div>
            <div>
                <p>Welcome webmasters and thank you for your interest in promoting {site}.</p>
                <p>
                    <strong>AFFILIATE LOGIN:</strong>
                    {` `}
                    <a href="https://www.verotel.com/login">Click Here</a>
                </p>
                <p>
                    <strong>AFFILIATE REGISTRATION:</strong>
                    {` `}
                    <a href="https://controlcenter.verotel.com/register-reseller?website=tl7ti6dhra9vlmwlhmpy18cbz9jf7tsmj7omoxxx">
                        Click Here
                    </a>
                </p>
                <p>
                    We are a small and simple operation so we currently use{` `}
                    <a href="https://controlcenter.verotel.com/register-reseller?website=u93dz4561swl5f3kofbokj321h7v31kwq2f6y5ie">
                        Verotel's
                    </a>
                    {` `}
                    built-in sales-tracking platform to record and track sales.
                </p>
                <p>
                    <strong>AFFILIATE PAYOUT:</strong> 50%
                </p>
                <p>
                    <strong>PRIMARY PAYMENT PROCESSOR:</strong> Verotel
                </p>
                <p>
                    <strong>METHODS OF PAYMENT:</strong> Cheques, Wire Transfers
                </p>
                <p>
                    We don't have much promotional content. If there is something you need, you can
                    {` `}
                    <a href={mailTo}>contact us</a> and we'll be happy to supply what we can and
                    make custom creatives if time permits
                </p>
            </div>
        </StyledPage>
    );
}
