export default async function promiseTree(value, cache = new WeakMap()) {
    if (!value || typeof value !== `object`) {
        return value;
    }

    if (cache.has(value)) {
        return cache.get(value);
    }

    const received = value instanceof Promise ? await value : value;

    if (!received || typeof received !== `object`) {
        cache.set(value, received);
        return received;
    }

    const result = Array.isArray(received) ? [] : {};
    cache.set(value, result);

    await Promise.all(
        Object.keys(received).map(async key => {
            result[key] = await promiseTree(received[key], cache);
        })
    );

    return result;
}
