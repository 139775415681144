import createAssetService from '../../argonath/asset.js';
import createMasonryService from '../../argonath/masonry.js';
import createTextService from '../../argonath/text.js';
import createBannerService from '../../argonath/banner.js';
import promiseTree from '../../../lib/promise-tree.js';
import createState from '../state.js';
import placeholder from '../../../lib/placeholder';

const DEFAULT_MASONRY = [];
const DEFAULT_SPECIAL = {
    portraight: {
        x1: placeholder(3, 5),
    },
    landscape: {
        x1: placeholder(16, 9),
    },
};
const DEFAULT_OUR_STORY = {
    name: ``,
    value: ``,
};

export default function createTourState({
    root,
    values: {
        masonry = DEFAULT_MASONRY,
        special = DEFAULT_SPECIAL,
        specialLink = `/join`,
        ourStory = DEFAULT_OUR_STORY,
    },
}) {
    const { serve } = createAssetService(root);
    const { list: listMasonry } = createMasonryService(root);
    const { find: textFind } = createTextService(root);
    const { list: bannerList } = createBannerService(root);

    const state = createState({
        root,

        values: {
            $page: `tour`,
            title: ``,
            masonry,
            special,
            specialLink,
            ourStory,
        },

        createActions,
    });

    return state;

    function createActions(state) {
        return { load };

        async function load() {
            if (state.special === DEFAULT_SPECIAL) {
                const { special, link } = await loadSpecial();
                state.special = special;
                state.specialLink = link;
            }

            if (state.masonry === DEFAULT_MASONRY) {
                state.masonry = await loadMasonry();
            }

            state.ourStory = await textFind(`our-story`);
        }
    }

    async function loadMasonry() {
        const masonryData = await listMasonry();
        const data = masonryData.map(({ file, poster, join }) => ({
            file: serveAsset(`/graphics/masonry/${file}`),
            poster: poster && serveAsset(`/graphics/masonry/${poster}`),
            join,
        }));
        const result = await promiseTree(data);
        return result;
    }

    async function loadSpecial() {
        const banner = (await bannerList()).reduce((c, n) => {
            const now = new Date();
            const current = new Date(c.start);
            const next = new Date(n.start);
            // if there is a current
            // and next is later than now (next is in future) => current
            // or current is later than next (current is newer) => current
            // else => next
            return c && (next - now > 0 || current - next > 0) ? c : n;
        });
        const data = {
            portraight: {
                x1: serveImage(`/graphics/homepage-${banner.name}-hero-small.jpg`),
            },
            landscape: {
                x1: serveImage(`/graphics/homepage-${banner.name}-hero-big.jpg`),
            },
        };
        const result = await promiseTree(data);
        return { special: result, link: banner.link || `/join` };
    }

    async function serveAsset(path) {
        if (!path) {
            return undefined;
        }
        const info = await serve(path);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return undefined;
        }

        if (!info.serve) {
            console.warn(`User does not have access to "${path}"`);
            return undefined;
        }

        return {
            mime: info.mime,
            file: info.serve.uri,
            type: info.serve.type,
        };
    }

    async function serveImage(path) {
        if (!path) {
            return undefined;
        }
        const info = await serve(path);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return undefined;
        }
        return info && info.serve && info.serve.uri;
    }
}
