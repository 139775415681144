import React, { useState } from 'react';
import styled from 'styled-components';

const StyledPage = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .entry {
        margin: 1em;
    }

    label {
        display: block;
        font-weight: 600;
    }

    input {
        display: block;
        margin-top: 0.25em;
        box-sizing: border-box;
        width: 100%;
        height: 2.4375em;
        box-shadow: inset 0 1px 2px rgba(var(--color-primary-text-rgb), 0.1);
        font-weight: normal;
        border: 1px solid rgba(var(--color-primary-text-rgb), 0.2);
        font-size: 1em;
        padding-left: 0.5em;
        color: var(--color-join-item-text);
        background-color: var(--color-join-item);
    }

    input[type='submit'] {
        display: inline-block;
        font-weight: 600;
        appearance: none;
        width: auto;
        border: 1px solid transparent;
        cursor: pointer;
        background-color: var(--color-join-button);
        color: var(--color-join-button-text);
    }

    .data {
        margin-left: auto;
        margin-right: auto;
    }

    .continue {
        text-align: right;
    }

    .error {
        color: var(--color-action);
        font-weight: 500;
    }
`;

export default function PagePasswordReset({ setUser, state }) {
    const [error, setError] = useState();

    return (
        <StyledPage className="page">
            <div className="data">
                <h1>Reset your password</h1>
                <form onSubmit={attemptPasswordReset}>
                    <div className="entry">
                        <label className="user-data">
                            Enter your new password:
                            <input
                                type="password"
                                name="password"
                                placeholder="Enter your new password"
                                autoComplete="new-password"
                                required
                                pattern="^[a-zA-Z0-9]{6,}$"
                                title="Minimum 6, 0-9 and letters"
                            />
                        </label>
                    </div>
                    <div className="entry">
                        <label className="user-data">
                            Re-Enter your new password:
                            <input
                                type="password"
                                name="confirm"
                                placeholder="Confirm your new password"
                                required
                                pattern="^[a-zA-Z0-9]{6,}$"
                                title="Minimum 6, 0-9 and letters"
                            />
                        </label>
                    </div>
                    <div className="error">{error}</div>
                    <div className="continue">
                        <input type="submit" value="CONTINUE >>" className="action" />
                    </div>
                </form>
            </div>
        </StyledPage>
    );

    async function attemptPasswordReset(event) {
        event.preventDefault();

        const interactionId = state.root.interaction.start();
        try {
            setError(undefined);

            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get(`code`);

            if (!code) {
                setError(`"code" missing from URL. Please contact support`);
                return;
            }

            const formData = new FormData(event.target);
            const form = Object.fromEntries(formData.entries());

            if (form.password !== form.confirm) {
                setError(`Passwords do not match`);
                return;
            }

            const response = await fetch(
                `${state.root.key.api}/api/legacy/user/change-password?site=${state.root.$site}`,
                {
                    method: `post`,
                    credentials: `include`,
                    headers: {
                        Accept: `application/json`,
                        'Content-Type': `application/json`,
                    },
                    body: JSON.stringify({
                        code,
                        password: form.password,
                    }),
                }
            );

            if (response.ok) {
                const { user, error } = await response.json();
                if (user) {
                    setUser(user);
                    state.root.page.navigate(`/`);
                    return;
                } else if (error.code === `auth/code-does-not-exists`) {
                    setError(`Password reset code already used`);
                    return;
                }
            }
            throw new Error();
        } catch (error) {
            console.error(`An error occurred: ${error.message}`);
            setError(`Failed. Please contact support`);
        } finally {
            state.root.interaction.end(interactionId);
        }
    }
}
