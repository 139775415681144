import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
    }
`;

const Title = styled.h2`
    font-size: ${({ size }) => size || `1em`};
    text-transform: ${({ textTransform }) => textTransform || `uppercase`};
    margin: 0.5em;
    color: var(--color-tertiary-text);

    ${({ blur }) =>
        blur &&
        `color: transparent;
        text-shadow: 0 0 10px rgba(0,0,0,0.5);`}
`;

export default function CardImageTitled({
    title,
    titleSize,
    textTransform,
    image,
    lazy,
    blurTitle,
}) {
    return (
        <Container>
            {lazy ? (
                <LazyLoadImage src={image.x1} alt={title} />
            ) : (
                <img src={image.x1} alt={title} />
            )}
            {title && (
                <Title blur={blurTitle} size={titleSize} textTransform={textTransform}>
                    {title}
                </Title>
            )}
        </Container>
    );
}
