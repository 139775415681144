import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import constants from '../../constants.js';

const CLOSE_KEYS = [`esc`, `escape`];

const SearchStyles = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: ${constants.size.small}) {
        :not(.searching) {
            .icon.search {
                position: fixed;
                bottom: 1em;
                right: 1em;
            }
        }

        position: absolute;
        left: 0.5em;
        width: 100%;
    }

    form {
        flex-grow: 1;
    }

    .icon {
        position: relative;
        display: block;
        flex-grow: 0;
        cursor: pointer;
        height: var(--height-header);
        width: var(--height-header);
        color: var(--color-action-active);
    }

    .icon.search svg {
        position: absolute;
        top: 15%;
        left: 0;
        height: 70%;
        width: var(--height-header);
    }

    .icon.close {
        height: 1em;
        width: 1em;
        font-size: calc(var(--height-header) * 0.8);
        margin-right: 0.5em;
    }

    input {
        width: 100%;
        border: 1px solid var(--color-border-action);
        border-radius: 5px;
    }

    :not(.searching) {
        input {
            display: none;
        }

        .icon.close {
            display: none;
        }
    }
`;

export default function SearchQuery({
    globalSearchKey = `/`,
    onChange,
    searching,
    setSearching,
    showResultsPage,
}) {
    const searchInputRef = useRef(null);
    useEffect(() => {
        if (globalSearchKey) {
            bind();
        }
        return unbind;

        function bind() {
            document.body.addEventListener(`keydown`, onBodyKeyDown);
        }

        function unbind() {
            document.body.removeEventListener(`keydown`, onBodyKeyDown);
        }
    });

    const classNames = [searching && `searching`].filter(Boolean).join(` `);
    return (
        <SearchStyles searching={searching} className={classNames}>
            <form onSubmit={onSearchSubmit}>
                <input
                    type="text"
                    name="query"
                    ref={searchInputRef}
                    placeholder="search"
                    onInput={onSearchInput}
                    onKeyDown={onSearchKeyDown}
                />
            </form>
            <a className="icon search" onClick={onSearchIconClick}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="128"
                    height="128"
                    viewBox="0 0 1280 1280"
                >
                    <g>
                        <g fill="currentColor">
                            <path d="M982 1117 c-163 -163 -167 -172 -103 -230 79 -71 65 -76 232 89 174 173 177 179 102 243 -27 23 -57 41 -68 41 -13 0 -73 -53 -163 -143z" />
                            <path d="M444 989 c-140 -17 -284 -112 -361 -239 -86 -141 -86 -359 0 -500 32 -53 113 -136 166 -169 153 -97 369 -97 522 0 53 33 134 116 166 169 66 109 84 277 43 401 -76 228 -296 367 -536 338z m206 -175 c73 -34 136 -96 171 -169 25 -52 29 -72 29 -145 0 -70 -5 -95 -26 -140 -34 -73 -96 -136 -169 -171 -52 -25 -72 -29 -145 -29 -73 0 -93 4 -145 29 -73 35 -135 98 -169 171 -37 79 -37 201 0 280 34 73 96 136 169 171 52 25 72 29 145 29 70 0 95 -5 140 -26z" />
                        </g>
                    </g>
                </svg>
            </a>
            <a className="icon close" onClick={onCloseClick}>
                ✖
            </a>
        </SearchStyles>
    );

    function onBodyKeyDown(event) {
        const { key } = event;
        if (key === globalSearchKey) {
            setSearching(true);
            focusSearch();
            event.preventDefault();
        }
    }

    function onSearchKeyDown(event) {
        event.stopPropagation();
        const { key } = event;
        const normKey = key.toLowerCase();
        if (CLOSE_KEYS.includes(normKey)) {
            onCloseClick();
        }
    }

    function onSearchInput(event) {
        onChange(event.target.value);
    }

    function onSearchSubmit(event) {
        event.preventDefault();
        const query = event.target.elements.query.value;
        if (query) {
            onChange(query);
            showResultsPage();
            setSearching(false);
        } else {
            setSearching(false);
        }
    }

    function onSearchIconClick() {
        if (searching) {
            if (searchInputRef.current) {
                onChange(searchInputRef.current.value);
            }
            showResultsPage();
            setSearching(false);
        } else {
            setSearching(true);
            // We need the dom to update
            setTimeout(focusSearch, 10);
        }
    }

    function onCloseClick() {
        setSearching(false);
    }

    function focusSearch() {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }
}
