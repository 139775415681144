import { gql } from '@apollo/client';
import { getClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createHomepageFeaturesService(state) {
    return {
        homepageFeatures,
    };

    function homepageFeatures() {
        return handleError(() => getHomepageFeatures());
    }

    async function getHomepageFeatures() {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query HomepageFeatures {
                    homepageFeatures {
                        homepageFeatures {
                            result {
                                title
                                content {
                                    title
                                    talentId
                                    videoId
                                }
                            }
                        }
                    }
                }
            `,
        });
        return result.data.homepageFeatures.homepageFeatures.result;
    }
}
