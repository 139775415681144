import React from 'react';
import { Stream } from '@cloudflare/stream-react';

export default function CloudflarePlayer(props) {
    const { src, controls, width, height, poster } = props;
    return (
        <Stream
            className="cloudflare-player"
            src={src}
            controls={controls}
            width={width}
            height={height}
            poster={poster}
            autoplay={false}
            preload="auto"
            primaryColor={process.env.THEME}
            onError={() => window.location.reload()}
        />
    );
}
