import React from 'react';
import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from './dialog.jsx';

const EMAIL =
    /^\s*[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\s*$/;

const Input = styled.input`
    margin: auto;
    margin-bottom: 5px;

    display: block;
    width: 100%;
`;

const Hint = styled.div`
    font-size: 0.8em;
    font-style: italic;
    margin-top: 0.5em;
`;

const Button = styled.button`
    display: block;
    margin: auto;
    margin-bottom: 5px;
    width: 100%;
    background-color: var(--color-action);
    color: var(--color-action-text);
    font-weight: bold;
    border: 1px solid transparent;
    padding: 0.85em 1em;
    border-radius: 2px;

    &:hover {
        background-color: var(--color-action-button);
    }
`;

const ForgotPassword = styled.a`
    display: block;
    margin: auto;
    width: 126px;
    color: var(--color-action-active);

    &:hover {
        color: var(--color-action-button);
    }
`;

function Login({ setUser, state }) {
    let widgetId;
    const ref = useRef(null);
    const [msg, setMsg] = useState(`LOGIN`);
    const [forcePasswordReset, setForcePasswordReset] = useState(false);

    useEffect(() => {
        (async function () {
            if (ref && state.key.captchaV2) {
                await new Promise(resolve => window.grecaptcha.ready(resolve));
                widgetId = window.grecaptcha.render(
                    `recaptcha`,
                    {
                        callback: token => {
                            onHaveCaptchaToken(token);
                        },
                    },
                    true
                );
            }
        })();
    }, [ref]);

    return (
        <>
            {forcePasswordReset ? (
                <Dialog name="login">
                    <h1>NEW PASSWORD REQUIRED</h1>
                    <p>
                        We've updated our system and a new password is required. Please click the
                        button to reset your password.
                    </p>
                    <a href="#reset">
                        <Button type="submit">RESET PASSWORD</Button>
                    </a>
                </Dialog>
            ) : (
                <Dialog name="login">
                    <h1>LOGIN</h1>
                    <form onSubmit={onSubmit}>
                        <Input
                            id="email"
                            type="email"
                            placeholder="email"
                            name="email"
                            autoComplete="email"
                            required
                        />
                        <Input
                            id="password"
                            type="password"
                            placeholder="password"
                            name="password"
                            autoComplete="current-password"
                            required
                        />
                        <Button type="submit">{msg}</Button>
                    </form>
                    <ForgotPassword href="#reset">forgot password?</ForgotPassword>
                    {state.root.key.captchaV2 && (
                        <div
                            id="recaptcha"
                            ref={ref}
                            data-sitekey={state.root.key.captchaV2}
                            data-badge="inline"
                            data-size="invisible"
                        />
                    )}
                </Dialog>
            )}
        </>
    );

    async function onSubmit(event) {
        event.preventDefault();
        let token;
        if (state.key.captcha || state.key.captchaV2) {
            await new Promise(resolve => window.grecaptcha.ready(resolve));
        }

        // TODO: How to move this to analytics long term?
        if (state.key.captcha) {
            token = await window.grecaptcha.execute(state.key.captcha, { action: `login` });
        }

        setMsg(`PLEASE WAIT`);
        if (state.key.captchaV2) {
            window.grecaptcha.reset(widgetId);
            // Note: We purposefully overwrite the token (if it was already set)
            const response = await window.grecaptcha.getResponse(widgetId);
            if (response) {
                onHaveCaptchaToken(response);
            } else {
                window.grecaptcha.execute(widgetId);
            }
        } else {
            onHaveCaptchaToken(token);
        }
    }

    async function onHaveCaptchaToken(token) {
        const email = document.getElementById(`email`).value;
        const passwordEle = document.getElementById(`password`);
        const password = passwordEle.value;
        try {
            const response = await fetch(
                `${state.key.api}/api/legacy/user/login?site=${state.$site}`,
                {
                    method: `post`,
                    credentials: `include`,
                    headers: {
                        Accept: `application/json`,
                        'Content-Type': `application/json`,
                    },
                    body: JSON.stringify({
                        email,
                        password,
                        token,
                    }),
                }
            );

            if (response.ok) {
                const { error, user } = await response.json();
                if (error) {
                    if (error.code === `auth/user-needs-reset`) {
                        setForcePasswordReset(true);
                        return;
                    }
                    throw new Error(`Login request returned an error with code ${error.code}`);
                }
                if (window.location.pathname === `/welcome`) {
                    window.location.assign(`/`);
                } else {
                    window.location.assign(`#`);
                }
                setUser(user);
                setMsg(`LOGIN`);
                return;
            }
        } catch (error) {
            console.error(`Error occurred during login`, error);
        }
        setMsg(`EMAIL OR PASSWORD INCORRECT`);
    }
}

export default Login;
