import assert from 'assert';
import { ApolloLink } from '@apollo/client';

const API_KEY = `argonath-api-key`;
const MODE = typeof window === `undefined` ? undefined : { mode: `cors` };

export default function createArgonathLink() {
    return new ApolloLink((operation, next) => {
        const { state, headers, fetchOptions } = operation.getContext();
        assert(state, `state MUST be set on the context`);
        assert(state.key.argonath, `argonath key MUST be defined on state`);

        operation.setContext(previous => ({
            ...previous,
            headers: {
                ...headers,
                [API_KEY]: state.key.argonath,
            },
            fetchOptions: {
                ...fetchOptions,
                ...MODE,
            },
        }));

        return next(operation);
    });
}
