import { gql } from '@apollo/client';
import { getClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createPackageService(state) {
    return {
        packages,
    };

    function packages() {
        return handleError(() => getPackages());
    }

    async function getPackages() {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query ListPackages {
                    package {
                        list {
                            result {
                                edges {
                                    node {
                                        sub
                                        code
                                        current
                                        previous
                                        description
                                        default
                                        trial
                                    }
                                }
                            }
                        }
                    }
                }
            `,
        });
        const found = result.data.package.list.result.edges.map(edge => edge.node);
        return found;
    }
}
