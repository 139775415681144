import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import MembershipOption from '../membership-option.jsx';
import Dialog from './dialog.jsx';

const Button = css`
    display: block;
    margin: auto;
    margin-bottom: 5px;
    background-color: var(--color-action);
    color: var(--color-primary);
    font-weight: bold;
    border: 1px solid transparent;
    padding: 0.85em 1em;

    &:hover {
        background-color: var(--color-action-button);
    }
`;

const InputButton = styled.input`
    ${Button}
`;

const AnchorButton = styled.a`
    ${Button}
`;

function LockdownDialog({ user, setUser, state, site, packages, activate, reactivate, upgrade }) {
    const [error, setError] = useState(undefined);
    return <Dialog name="lockdown">{createDialog()}</Dialog>;

    function createDialog() {
        switch (user && user.type) {
            case `trial`:
                if (
                    !user.trialCredit &&
                    state.page.current.video &&
                    state.page.current.video.videoId
                ) {
                    return (
                        <>
                            <h1>Trial</h1>
                            <p>You have access to one video.</p>
                            <form onSubmit={attemptTrialCredit}>
                                <h2>Do you want to watch this video?</h2>
                                <InputButton
                                    type="submit"
                                    value="WATCH"
                                    className="button primary primary-text"
                                />
                            </form>
                        </>
                    );
                }
                return (
                    <>
                        <h1>Upgrade</h1>
                        <p>
                            Your trial lets you watch one video. Upgrade your account for full
                            access.
                        </p>
                        <form onSubmit={attemptUpgrade}>
                            <h2>Choose Your Membership</h2>
                            {packages
                                .filter(option => !option.code.includes(`trial`))
                                .map(MembershipOption)}
                            <InputButton
                                type="submit"
                                value="CONTINUE"
                                className="button primary primary-text"
                            />
                        </form>
                    </>
                );
            case `expired`:
                return (
                    <>
                        <h1>Expired</h1>
                        <p>Your account has expired. Re-activate your account for full access.</p>
                        <form onSubmit={attemptReactivate}>
                            <h2>Re-activate Now</h2>
                            {packages
                                .filter(option => !option.code.includes(`trial`))
                                .map(MembershipOption)}
                            <InputButton
                                type="submit"
                                value="CONTINUE"
                                className="button primary primary-text"
                            />
                        </form>
                        {<div className="error">{error}</div>}
                    </>
                );
            // case `linked`:
            //     return (
            //         <>
            //             <h1>Activate</h1>
            //             <p>You need to activate your account for full access.</p>
            //             <form onSubmit={attemptActivate}>
            //                 <h2>Activate Now</h2>
            //                 {packages.map(MembershipOption)}
            //                 <InputButton
            //                     type="submit"
            //                     value="CONTINUE"
            //                     className="button primary primary-text"
            //                 />
            //             </form>
            //         </>
            //     );
            default:
                return (
                    <>
                        <h1>Join {site.toUpperCase()}</h1>
                        <p>An account is required to access this content. Join to get access!</p>
                        <AnchorButton href="/join" className="button">
                            Join Now
                        </AnchorButton>
                    </>
                );
        }
    }

    async function attemptTrialCredit(event) {
        event.preventDefault();
        setError(undefined);
        try {
            const response = await fetch(
                `${state.root.key.api}/api/legacy/user/trial-credit?site=${state.root.$site}`,
                {
                    method: `post`,
                    credentials: `include`,
                    headers: {
                        Accept: `application/json`,
                        'Content-Type': `application/json`,
                    },
                    body: JSON.stringify({
                        trialCredit: state.page.current.video.videoId,
                    }),
                }
            );

            if (response.ok) {
                const { error, user } = await response.json();
                if (error) {
                    throw new Error(
                        `Set trial credit request returned an error with code ${error.code}`
                    );
                }
                setUser(user);
                window.location.assign(`#`);
                return;
            }
        } catch (error) {
            console.error(`Error during trial credit`, error);
            setError(`An error occurred. Please contact support.`);
        }
    }

    async function attemptUpgrade(event) {
        event.preventDefault();

        // TODO: This should come from some global data
        //  dependency
        // const tracking = getTracking();
        const formData = new FormData(event.target);
        const form = Object.fromEntries(formData.entries());

        await upgrade({
            package: form.package,
        });
    }

    async function attemptReactivate(event) {
        event.preventDefault();
        setError(undefined);
        const formData = new FormData(event.target);
        const form = Object.fromEntries(formData.entries());
        try {
            const response = await fetch(
                `${state.root.key.api}/api/legacy/user/reactivate?site=${state.root.$site}`,
                {
                    method: `post`,
                    credentials: `include`,
                    headers: {
                        Accept: `application/json`,
                        'Content-Type': `application/json`,
                    },
                    body: JSON.stringify({
                        package: form.package,
                    }),
                }
            );
            if (response.ok) {
                const resp = await response.json();
                if (resp.error) {
                    throw new Error();
                }

                window.location.href = resp.link;
            }
        } catch (error) {
            setError(`An error occurred. Please contact support.`);
        }
    }

    // async function attemptActivate(event) {
    //     event.preventDefault();
    //     // TODO: This should come from some global data
    //     //  dependency
    //     // const tracking = getTracking();
    //     const formData = new FormData(event.target);
    //     const form = Object.fromEntries(formData.entries());

    //     await activate({
    //         package: form.package,
    //     });
    // }
}

export default LockdownDialog;
