import React from 'react';
import styled from 'styled-components';

const StyledOverlay = styled.div`
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-overlay);
    z-index: 1002;

    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;

    .text {
        color: var(--color-primary);
        font-size: 3.5em;
    }

    .spinner,
    .text {
        margin-left: auto;
        margin-right: auto;
        width: auto;
        text-align: center;
    }

    .spinner {
        position: relative;
        width: 80px;
        height: 80px;
    }

    .spinner div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: var(--color-spinner);
        animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .spinner div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }
    .spinner div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }
    .spinner div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes spinner {
        0% {
            top: 8px;
            height: 64px;
        }
        50%,
        100% {
            top: 24px;
            height: 32px;
        }
    }
`;

export default function BusyOverlay(props) {
    const { children, text, state, ...rest } = props;
    const loading = state.interaction.useBusy();
    if (!loading) {
        return null;
    }

    return (
        <StyledOverlay {...rest}>
            {loading && (
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
            {text && <div className="text">{text}</div>}
            {children}
        </StyledOverlay>
    );
}
