import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
    ${({ id }) => css`
        &#${id}:not(:target) {
            display: none;
        }

        &#${id}:target {
            display: flex;
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: var(--color-overlay);
            z-index: 1000;
            justify-content: center;
            align-items: center;
        }
    `}
`;

const Styles = styled.div`
    min-width: 20em;
    position: relative;
    background-color: var(--color-primary);
    z-index: 1003;
    border-radius: 0.5em;
    padding: 2em;
    margin: 1rem;
    text-align: center;
    font-size: 1em;
    border: 0.25em solid var(--color-action-active);

    a.close {
        color: var(--color-primary-text);
        font-weight: bold;
        font-size: 1.5rem;
        position: absolute;
        top: 0.5rem;
        right: 1rem;
    }

    h1 {
        font-size: 3rem;
        text-transform: uppercase;
    }

    .g-recaptcha {
        transform: scale(0.86);
        -webkit-transform: scale(0.86);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }

    @media (max-width: 25em) {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        border: 0;
        border-radius: 0;
        padding: 1em;

        h1 {
            font-size: 2rem;
        }
    }
`;

function Dialog({ children, name, onClose }) {
    return (
        <Container id={name}>
            <Styles>
                <div style={{ flexGrow: 1 }} />
                {children}
                <div style={{ flexGrow: 1 }} />
                <a href="#" className="close" onClick={onClose}>
                    X
                </a>
            </Styles>
        </Container>
    );
}

export default Dialog;
