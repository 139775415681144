import React from 'react';
import styled from 'styled-components';
import constant from '../constants.js';

const StyledCards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 1px;
    margin-right: 1px;

    & > * {
        box-sizing: border-box;
        display: inline-block;
        padding-left: 1px;
        padding-right: 1px;
        padding-bottom: 0.5em;
        flex-grow: 0;
        flex-shrink: 1;

        flex-basis: ${({ desktopColumns }) => 100 / desktopColumns}%;

        @media (max-width: ${constant.size.mobile}) {
            flex-basis: ${({ mobileColumns }) => 100 / mobileColumns}%;
        }
    }
`;

export default function Cards({ children, desktopColumns = 3, mobileColumns = 1 }) {
    const childrenArray = React.Children.toArray(children);
    return (
        <StyledCards desktopColumns={desktopColumns} mobileColumns={mobileColumns}>
            {childrenArray.map(child => (
                <div key={child.key}>{child}</div>
            ))}
        </StyledCards>
    );
}
