import React from 'react';
import styled from 'styled-components';
import constants from '../../constants.js';

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    margin: 1em;

    @media (min-width: ${constants.size.standard}) {
        flex-direction: row;
        div {
            margin-top: 10em;
        }
    }
`;

function CardError({ title, info, error, state, image }) {
    const site = state.root.$site;
    const email = state.root.email.$support;
    const subject = encodeURIComponent(`${site} site error`);
    const body = encodeURIComponent(
        [
            `An error occurred on ${site}.com.`,
            ``,
            ...Object.entries(errorContext()).map(([name, value]) => `${name}: ${value}`),
            ``,
            error,
        ].join(`\n`)
    );

    const mailLink = `mailto:${email}?subject=${subject}&body=${body}`;
    return (
        <StyledCard>
            {image}
            <div style={{ flexGrow: 1 }}>
                <h1>{title}</h1>
                <p>{info}</p>
                {error && (
                    <>
                        <p>
                            Try again, and if the problem persists, please send this to{` `}
                            <a href={mailLink}>{email}</a> (Text please, no screenshots)
                        </p>
                        <div>
                            <span className="break">{error}</span>
                        </div>
                    </>
                )}
            </div>
        </StyledCard>
    );

    function errorContext() {
        if (typeof window === `undefined`) {
            return {};
        }

        const { innerWidth: width, innerHeight: height } = window;

        return {
            URL: window.location.href,
            Time: new Date().toISOString(),
            'User Agent': navigator.userAgent,
            Screen: `${width}x${height}`,
        };
    }
}

export default CardError;
