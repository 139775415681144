import { gql } from '@apollo/client';
import { getClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createTextService(state) {
    return {
        find,
    };

    function find(slug) {
        return handleError(() => doFind(slug));
    }

    async function doFind(slug) {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query FindText($slug: ID!) {
                    text {
                        find(input: { slug: $slug }) {
                            result {
                                slug
                                name
                                value
                            }
                        }
                    }
                }
            `,
            variables: { slug },
        });
        return result.data.text.find.result;
    }
}
