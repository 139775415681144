import { gql } from '@apollo/client';
import { getClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createTalentService(state) {
    return {
        find,
        list,
    };

    function find(talentId) {
        return handleError(() => doFind(talentId));
    }
    function list(...args) {
        return handleError(() => doList(...args));
    }

    async function doFind(talentId) {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query FindTalent($talentId: ID!) {
                    talent {
                        find(input: { talentId: $talentId }) {
                            result {
                                talentId
                                name
                                videos {
                                    videoId
                                    title
                                    description {
                                        short
                                        long
                                    }
                                }
                                dimensions {
                                    height
                                    weight
                                    measurements {
                                        cup
                                        waist
                                        hips
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables: { talentId },
        });
        return result.data.talent.find.result;
    }

    async function doList(order, desc, after, limit) {
        const client = await getClient(state);
        if (order) {
            order = [
                {
                    field: order,
                    desc,
                },
            ];
        }

        const result = await client.query({
            query: gql`
                query ListTalent($order: [OrderEntry!], $after: ID, $limit: Int) {
                    talent {
                        list(input: { order: $order, after: $after, first: $limit }) {
                            result {
                                edges {
                                    node {
                                        talentId
                                        name
                                    }
                                    cursor
                                }
                                pageInfo {
                                    hasNextPage
                                }
                            }
                        }
                    }
                }
            `,
            variables: { order, after, limit },
        });
        const connection = result.data.talent.list.result;
        return connection.edges.map((edge, idx, arr) => ({
            ...edge.node,
            cursor: edge.cursor,
            isLast: idx === arr.length - 1 && !connection.pageInfo.hasNextPage,
        }));
    }
}
