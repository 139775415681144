import React from 'react';
import styled from 'styled-components';

const StyledJoinButton = styled.div`
    text-align: center;
`;

const Button = styled.div`
    display: inline-block;
    padding-top: 1em;
    padding-bottom: 1em;
    color: var(--color-tertiary);
    text-transform: uppercase;
    font-size: 1.7em;
    font-weight: 700;
`;

export function JoinButton({ children }) {
    return (
        <StyledJoinButton>
            <a href="/join">
                <Button className="button primary">{children}</Button>
            </a>
        </StyledJoinButton>
    );
}
