import React from 'react';
import Navigate from '../container/navigate.jsx';

function Logo(props) {
    const { onClick, state } = props;
    return (
        <Navigate className="logo" state={state} href="/" onClick={onClick}>
            <img id="logo-image" src="/logo.svg" width="125" height="70" />
        </Navigate>
    );
}

export default Logo;
