import createState from '../state.js';

export default function createWelcomeState({ root }) {
    return createState({
        root,

        values: {
            $page: `welcome`,
            title: `Welcome`,
            waiting: false,
        },

        createActions,
    });

    function createActions(state) {
        return {
            load,
        };

        async function load() {
            // const interactionId = root.interaction.start();
            // state.waiting = true;
            // try {
            //     await root.user.welcomeLogin();
            // } finally {
            //     root.interaction.end(interactionId);
            //     state.waiting = false;
            // }
        }
    }
}
