import React from 'react';
import styled from 'styled-components';

const StyledPage = styled.div`
    text-align: center;
`;

export default function PageWelcome() {
    return (
        <StyledPage className="page">
            <h1>Thank You And Welcome!</h1>
            <p>Thank you for becoming a member. We hope you enjoy our fit, young, girls.</p>
            <p>It could take a moment for your account to be created</p>
            <p>
                <a href="#login">Click here to login</a>
            </p>
        </StyledPage>
    );
}
