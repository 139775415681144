import { gql } from '@apollo/client';
import { getAuthenticatedClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createPurchaseService(state) {
    return {
        pay,
        upgrade,
    };

    function pay(...args) {
        return handleError(() => doPay(...args));
    }

    function upgrade(...args) {
        return handleError(() => doUpgrade(...args));
    }

    async function doPay({ email, password, tracking, package: pkg, biller, reactivation }) {
        // Note: We use authed client for things like re-activation
        const client = await getAuthenticatedClient(state);
        const result = await client.mutate({
            mutation: gql`
                mutation Pay($input: PayInput!) {
                    purchase {
                        pay(input: $input) {
                            result {
                                type
                                uri
                                token
                                message
                                options
                            }
                        }
                    }
                }
            `,
            variables: {
                input: {
                    email,
                    password,
                    tracking,
                    package: pkg,
                    biller,
                    reactivation,
                },
            },
        });
        return result.data.purchase.pay.result;
    }

    async function doUpgrade({ biller, userId, package: pkg }) {
        const client = await getAuthenticatedClient(state);
        const result = await client.mutate({
            mutation: gql`
                mutation Upgrade($input: UpgradeInput!) {
                    purchase {
                        upgrade(input: $input) {
                            result {
                                type
                                uri
                                token
                                message
                                options
                            }
                        }
                    }
                }
            `,
            variables: {
                input: {
                    userId,
                    biller,
                    package: pkg,
                },
            },
        });
        return result.data.purchase.upgrade.result;
    }
}
