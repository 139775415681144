import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Navigate from '../container/navigate.jsx';
import SearchQuery from './search/query.jsx';
import constants from '../constants.js';

const MenuStyles = styled.div`
    width: ${props => (props.searching ? `100%` : `auto`)};
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: flex-end;
        margin: 0;
    }
    li {
        display: block;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .search-focus {
        li:not(.search) {
            display: none;
        }
        li.search {
            width: 100%;
        }
    }

    @media (max-width: ${constants.size.small}) {
        li.videos,
        li.models {
            display: none;
        }
    }
`;

function Menu(props) {
    const { onVideosClick, onModelsClick, onJoinClick, isLoggedIn, state } = props;

    const searchShown = state.search.useSearchShown();
    const menuClass = [`menu`, searchShown && `search-focus`].filter(Boolean).join(` `);

    return (
        <>
            <MenuStyles searching={searchShown}>
                <ul className={menuClass} role="menu" style={{ padding: 0 }}>
                    <li className="secondary-text search">
                        <SearchQuery
                            searching={searchShown}
                            setSearching={value => {
                                state.search.searchShown = value;
                            }}
                            onChange={value => {
                                state.search.query = value;
                            }}
                            showResultsPage={() =>
                                state.page.navigate(
                                    `/search-results?q=${encodeURIComponent(state.search.query)}`
                                )
                            }
                        />
                    </li>
                    <li className="secondary-text videos">
                        <Navigate state={state} href="/videos" onClick={onVideosClick}>
                            Videos
                        </Navigate>
                    </li>
                    <li className="secondary-text models">
                        <Navigate state={state} href="/models" onClick={onModelsClick}>
                            Models
                        </Navigate>
                    </li>
                    {!isLoggedIn && (
                        <>
                            <li className="secondary-text login">
                                <a href="#login">Login</a>
                            </li>
                            <li className="button action join">
                                <Navigate state={state} href="/join" onClick={onJoinClick}>
                                    Join
                                </Navigate>
                            </li>
                        </>
                    )}
                </ul>
            </MenuStyles>
        </>
    );
}

export default Menu;
