import React from 'react';
import styled from 'styled-components';
import constants from '../../constants.js';

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .column2 {
        width: 50%;
    }
    .column3 {
        width: 33.333%;
    }
    .column4 {
        width: 25%;
    }
    .column5 {
        width: 20%;
    }
    .column6 {
        width: 16.666%;
    }
`;

export default function Masonry(props) {
    const { documentWidth, children: elements, columns: explicitColumns = 6, ...rest } = props;
    const colCount = Math.min(explicitColumns, columnCount(documentWidth));

    const columns = Array(colCount)
        .fill(0)
        .map((value, colIdx) => {
            const result = [];
            for (let eleIdx = colIdx; eleIdx < elements.length; eleIdx = eleIdx + colCount) {
                result.push(elements[eleIdx]);
            }
            return result;
        });
    return (
        <StyledDiv {...rest}>
            {columns.map((column, index) => {
                const columnClass = `column${colCount}`;
                return (
                    <div key={index} className={columnClass}>
                        {column}
                    </div>
                );
            })}
        </StyledDiv>
    );
}

function columnCount(documentWidth) {
    if (documentWidth <= pxValue(constants.size.small)) {
        return 2;
    } else if (documentWidth <= pxValue(constants.size.standard)) {
        return 3;
    } else if (documentWidth <= pxValue(constants.size.large)) {
        return 5;
    } else {
        return 6;
    }
}

function pxValue(cssValue) {
    const un = unit(cssValue);
    const value = parseFloat(cssValue);
    switch (un) {
        case `px`:
            return value;
        case `em`:
            return value * baseFontSize();
        default:
            throw new Error(`CSS unit ${un} not supported`);
    }
}

function unit(value) {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
        return value;
    }
    const unit = value.substr(String(numberValue).length);
    return unit || `px`;
}

function baseFontSize() {
    if (typeof document === `undefined`) {
        return 16;
    } else {
        return parseFloat(getComputedStyle(document.documentElement).fontSize);
    }
}
