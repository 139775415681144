import React from 'react';
import styled from 'styled-components';
import Logo from './logo.jsx';
import Menu from './menu.jsx';
import SearchResults from './search/results.jsx';

const StyledHeader = styled.header`
    .header {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .logo {
        flex-grow: 0;
    }
    .spacer {
        flex-grow: 1;
    }

    .search-results {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(var(--height-header) + 0.3em);
        border: 2px solid var(--color-action-active);
        max-width: 40em;
        margin-left: auto;
        margin-right: auto;
    }
`;

function Header(props) {
    const { onLogoClick, onVideosClick, onModelsClick, onJoinClick, isLoggedIn, hideMenu, state } =
        props;
    return (
        <StyledHeader>
            <div className="header">
                <Logo state={state} onClick={onLogoClick} />
                <div className="spacer"></div>
                {!hideMenu && (
                    <Menu
                        isLoggedIn={isLoggedIn}
                        onVideosClick={onVideosClick}
                        onModelsClick={onModelsClick}
                        onJoinClick={onJoinClick}
                        state={state}
                    />
                )}
            </div>
            <SearchResultsBox state={state} />
        </StyledHeader>
    );
}

function SearchResultsBox({ state }) {
    const query = state.search.useQuery();
    const searchShown = state.search.useSearchShown();
    const results = state.search.useResults();
    const isSearching = state.search.useSearching();
    const notOnSearchPage = state.page.path !== `/search-results`;
    const shouldShow = query && searchShown && (isSearching || results) && notOnSearchPage;
    if (shouldShow) {
        return <SearchResults className="search-results" state={state} />;
    } else {
        return null;
    }
}

export default Header;
