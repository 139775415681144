import createState from './state.js';

export default function createDimensionState({ root, values: { width, height } }) {
    const state = createState({
        root,
        values: {
            width,
            height,
        },
        derived: {
            dimensions: {
                from: [`width`, `height`],
            },
        },
    });
    return state;
}
