import createState from './state.js';
import createAssetService from '../argonath/asset.js';
import createSearchService from '../argonath/search.js';

const DEBOUNCE = 600;

export default function createSearchState({ root }) {
    const { serve } = createAssetService(root);
    const { search: searchService } = createSearchService(root);
    let debouncedSearch;
    return createState({
        root,
        values: {
            query: ``,
            searching: false,
            searchShown: false,
        },
        derived: {
            results: {
                from: [`query`],
                combine: search,
            },
        },
    });

    async function search(state) {
        if (!state.query) {
            return undefined;
        }

        const results = await debSearch(state);
        const result = await Promise.all(
            results.map(async result => ({
                ...result,
                images: await Promise.all(result.images.map(serveImage)),
            }))
        );
        return result;
    }

    async function serveImage(path) {
        const info = await serve(path);
        return info && info.serve && info.serve.uri;
    }

    function debSearch(state) {
        if (!debouncedSearch) {
            debouncedSearch = debounce(() => execSearch(state), DEBOUNCE);
        }
        return debouncedSearch();
    }

    async function execSearch(state) {
        try {
            state.searching = true;
            return await searchService(state.query);
        } finally {
            state.searching = false;
        }
    }

    function debounce(handler, wait) {
        let timeout;
        return function exec() {
            if (timeout) {
                clearTimeout(timeout);
            }
            return new Promise(function promiseHandler(resolve, reject) {
                timeout = setTimeout(async () => {
                    try {
                        const result = await handler();
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                }, wait);
            });
        };
    }
}
