export function get(key) {
    try {
        return window.localStorage.getItem(key);
    } catch (error) {
        return undefined;
    }
}

export function set(key, value) {
    try {
        return window.localStorage.setItem(key, value);
    } catch (error) {
        return undefined;
    }
}

export function del(key) {
    try {
        return window.localStorage.removeItem(key);
    } catch (error) {
        return undefined;
    }
}

export function getData(key) {
    const value = get(key);
    if (value === undefined) {
        return undefined;
    }
    try {
        return JSON.parse(value);
    } catch (error) {
        throw new Error(`Unable to parse data value at key ${key}. ${error.message}. ${value}`);
    }
}

export function setData(key, value) {
    const serialized = JSON.stringify(value);
    return set(key, serialized);
}
