import React from 'react';
import ReactDOM from 'react-dom';
import merge from 'lodash.merge';
import throttle from 'lodash.throttle';
import App from './container/app.jsx';
import reportWebVitals from './reportWebVitals';
import createAppState from './service/state/app.js';

const VERSION_ROUTE = /^v\d+_\d+_\d+.*$/;
const RESIZE_THROTTLE = 300;
const DYNAMIC = [`/welcome`];

// const tokens = {
//     refreshToken: getData(PERSISTED_REFRESH_TOKEN_NAME),
//     idToken: getData(PERSISTED_ID_TOKEN_NAME),
// };

// const mobileRedirectLink = process.env.MOBILE_REDIRECT_LINK;

// if (mobileRedirectLink && isMobile(navigator.userAgent)) {
//     const urlParams = new URLSearchParams(window.location.search);
//     const redirectParam = urlParams.get(`redirect`);
//     const fromAffiliate = !!urlParams.get(`reseller_id`);
//     const redirectCookie = readCookie(`redirect`);

//     if (redirectParam === `false` || fromAffiliate) {
//         createCookie(`redirect`, `false`, 999);
//     }

//     if (
//         !tokens.idToken &&
//         redirectCookie !== `false` &&
//         redirectParam !== `false` &&
//         !fromAffiliate
//     ) {
//         window.location.replace(mobileRedirectLink);
//     }
// }

// function createCookie(name, value, days) {
//     if (days) {
//         const date = new Date();
//         date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//         document.cookie = `${name}=${value}; expires=${date.toGMTString()}`;
//     } else {
//         document.cookie = `${name}=${value}`;
//     }
// }

// function readCookie(name) {
//     const cookie = document.cookie.split(`;`).find(c => c.trimLeft().startsWith(`${name}=`));

//     if (cookie) {
//         return cookie.replace(`${name}=`, ``).trim();
//     }
// }

// function isMobile(agent) {
//     return (
//         typeof agent === `string` &&
//         [/Android/i, /webOS/i, /iPhone/i, /iPod/i, /BlackBerry/i, /Windows Phone/i].some(check =>
//             check.test(agent)
//         )
//     );
// }

const state = createAppState({
    key: {
        // Note: We purposefully leave the captcha
        //  keys out here so they won't be available
        //  in dev (where they should not be needed)
        api: process.env.API_URL,
        argonath: process.env.ARGONATH_API_KEY,
        rollbar: process.env.ROLLBAR,
    },
    email: {
        $support: process.env.SUPPORT_EMAIL,
        $contact: process.env.CONTACT_EMAIL,
    },
    $site: process.env.SITE,
    $catchPhrase: process.env.CATCH_PHRASE,
    $topic: process.env.TOPIC,

    dimension: {
        width: window.innerWidth,
        height: window.innerHeight,
    },

    interstitial: {
        interstitial: process.env.INTERSTITIAL,
    },

    ...merge(window.__INITIAL__DATA__, {}),
});

// We do this so when a member returns they don't get the server side rendered version
const shouldHydrate =
    window.__INITIAL__DATA__ && DYNAMIC.includes(window.location.pathname) === false;

performRender();
setImmediate(postInit);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function subscribe() {
    window.addEventListener(`popstate`, event => {
        const { state: page } = event;
        if (page) {
            state.page.navigate(page);
        } else {
            // The initial page has a value of null which we need to handle
            const relative = [
                window.location.pathname,
                window.location.search,
                window.location.hash,
            ].join(``);
            state.page.navigate(relative);
        }
    });

    window.addEventListener(
        `resize`,
        throttle(() => {
            const { innerWidth: width, innerHeight: height } = window;
            state.dimension.width = width;
            state.dimension.height = height;
        }, RESIZE_THROTTLE)
    );

    // Note: It is important we only do this when everything is ready since
    //  subscribe will trigger immediately
    state.page.title$.subscribe({
        next: current => {
            document.title = current;
        },
    });

    state.page.path$.subscribe({
        next: current => {
            if (current && window.history.state !== state.page.path) {
                let pagePath = state.page.path;

                // TODO: Don't think this is available on older browsers
                const [, firstPart] = window.location.pathname.split(`/`);
                if (VERSION_ROUTE.test(firstPart)) {
                    pagePath = `/${firstPart}${pagePath}`;
                }

                if (pagePath === `/password-reset` && window.location.search.startsWith(`?code=`)) {
                    const urlParams = new URLSearchParams(window.location.search);
                    const code = urlParams.get(`code`);
                    window.history.pushState(
                        pagePath,
                        state.page.title,
                        `${pagePath}?code=${code}`
                    );
                } else {
                    window.history.pushState(pagePath, state.page.title, pagePath);
                }
                // TODO: Not on hash change
                window.scrollTo(0, 0);
            }
        },
    });
}

function performRender() {
    if (shouldHydrate) {
        ReactDOM.hydrate(
            <React.StrictMode>
                <App state={state} error={window.__ERROR__} />
            </React.StrictMode>,
            document.getElementById(`root`)
        );
    } else {
        ReactDOM.render(
            <React.StrictMode>
                <App state={state} error={window.__ERROR__} />
            </React.StrictMode>,
            document.getElementById(`root`)
        );
    }
}

function postInit() {
    // Kick off the packages load
    state.biller.load();

    if (!shouldHydrate) {
        // TODO: hashbang
        state.page.navigate(`${window.location.pathname}${window.location.search}`);
    }

    state.dimension.width = window.innerWidth;
    state.dimension.height = window.innerHeight;

    subscribe();
}
