import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
    padding: 1em;
    display: inline-block;
`;

export default function CardImage({ image, alt }) {
    return (
        <StyledCard className="card border">
            <div className="image">
                <img src={image.x1} alt={alt} />
            </div>
        </StyledCard>
    );
}
