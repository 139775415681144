import React from 'react';
import styled from 'styled-components';
import Navigate from '../../container/navigate.jsx';
import { Spinner } from '../element/spinner.jsx';

const ResultStyles = styled.div`
    .search-entry {
        display: flex;
        flex-direction: row;
        color: var(--color-action-text);
        background-color: rgba(var(--color-action-button-rgb), 0.8);
        cursor: pointer;
        transition: background-color 250ms ease-out;

        .image {
            width: 20%;
            flex-grow: 0;
            box-sizing: border-box;
            padding: 0.5em;

            img {
                width: 100%;
                height: auto;
                border: var(--size-border) solid var(--color-border);
            }
        }

        .details {
            flex-grow: 1;
            width: 80%;
            box-sizing: border-box;
            padding: 0.5em;

            h3 {
                line-height: 1.2em;
                margin: 0.1em;
            }

            .description {
                font-family: Comfortaa;
                font-size: 1rem;
                line-height: 1em;
                text-transform: none;
                font-weight: 300;
            }
        }
    }

    .search-entry:hover {
        background-color: var(--color-action-button);
    }
`;

export default function SearchResults({ className, state }) {
    const results = state.search.useResults();
    const isSearching = state.search.useSearching();
    const hasResults = !isSearching && results && results.length > 0;

    return (
        <ResultStyles className={className}>
            {isSearching && (
                <div className="search-entry">
                    <Spinner />
                </div>
            )}
            {!isSearching && !hasResults && <div className="search-entry">No results</div>}
            {hasResults &&
                results.map(result => (
                    <Navigate
                        state={state.root}
                        key={itemKey(result)}
                        href={navigateLink(result)}
                        onClick={event => onItemClick(event, result)}
                    >
                        <div className="search-entry">
                            <div className="image">
                                <img src={result.images[0]} />
                            </div>
                            <div className="details">
                                <h3>{result.name}</h3>
                                <div className="description">{result.description}</div>
                            </div>
                        </div>
                    </Navigate>
                ))}
        </ResultStyles>
    );

    function navigateLink(item) {
        switch (item.type) {
            case `TALENT`:
                return `/models/${item.itemId}`;
            case `VIDEO`:
                return `/videos/${item.itemId}`;
            default:
                console.error(`Unrecognized item type "${item.type}"`);
                break;
        }
    }

    function itemKey(item) {
        return `${item.type}:${item.itemId}`;
    }

    function onItemClick(event, result) {
        event.preventDefault();
        state.root.search.searchShown = false;
        state.root.page.navigate(navigateLink(result));
    }
}
