import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import CardImage from '../component/card/image.jsx';
import MembershipOption from '../component/membership-option.jsx';
import { getTracking } from '../lib/tracking.js';
import constants from '../constants.js';

import image4k from '../artifacts/4k.svg';
import imagePaypal from '../artifacts/paypal-by-epoch.svg';
import imageResponsive from '../artifacts/responsive.svg';

const PageBackgroundStyle = createGlobalStyle`
    body {
        background-color: var(--color-join-bg);
    }

    footer {
        background-color: transparent !important;
        color: var(--color-border-dark) !important;
    }
`;

const StyledPage = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    h1 {
        text-transform: uppercase;
        color: var(--color-border-dark);
    }

    .user-data {
        color: var(--color-border-dark);
    }

    .error {
        color: var(--color-action);
        font-weight: 500;
    }

    .card {
        background-color: var(--color-join-item);
        margin-top: 1em;
        width: 100%;
        box-sizing: border-box;
    }

    .card-image {
        min-width: 33%;
    }

    .card-image img {
        width: 100%;
    }

    .data {
        margin-left: 2em;
    }

    @media (max-width: ${constants.size.medium}) {
        .card-image {
            display: none;
        }

        .data {
            margin-left: 1em;
        }
    }

    .entry {
        margin: 1em;
    }

    label {
        display: block;
        font-weight: 600;
    }

    input {
        display: block;
        margin-top: 0.25em;
        box-sizing: border-box;
        width: 100%;
        height: 2.4375em;
        box-shadow: inset 0 1px 2px rgba(var(--color-primary-text-rgb), 0.1);
        font-weight: normal;
        border: 1px solid rgba(var(--color-primary-text-rgb), 0.2);
        font-size: 1em;
        padding-left: 0.5em;
        color: var(--color-join-item-text);
        background-color: var(--color-join-item);
    }

    input[type='submit'] {
        display: inline-block;
        font-weight: 600;
        appearance: none;
        width: auto;
        border: 1px solid transparent;
        cursor: pointer;
        background-color: var(--color-join-button);
        color: var(--color-join-button-text);
    }

    .privacy {
        color: var(--color-privacy-text);
        font-size: 0.9em;
    }

    .info {
        display: flex;
        background-color: var(--color-join-item);
        margin-top: 2em;
        margin-right: 1em;
        border: 1px solid rgba(var(--color-primary-text-rgb), 0.2);
        flex-direction: column;
        color: var(--color-border-dark);
    }

    .info > div {
        justify-content: space-around;
    }

    .info > div > h3 {
        margin-left: 2em;
        margin-right: 2em;
        text-align: center;
        text-transform: uppercase;
    }

    @media (max-width: ${constants.size.small}) {
        .info > div > img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 25%;
        }

        .info > div > .paypal {
            width: 50%;
        }

        .info {
            padding: 1em;
        }
    }

    @media (min-width: ${constants.size.small}) {
        .info {
            flex-direction: row;
        }

        .info > div {
            flex-shrink: 1;
            flex-grow: 1;
            width: 33%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid var(--color-border);
            margin-top: 1em;
            margin-bottom: 1em;
        }

        .info > div:last-child {
            border-right: 0;
        }

        .info > div > img {
            color: var(--color-secondary-text);
            width: 50%;
        }

        .info > div > .paypal {
            width: 75%;
        }
    }
`;

export default function PageJoin({ state }) {
    const packages = state.root.biller.usePackages();
    const picture = state.usePicture();
    const [error, setError] = useState(undefined);

    // TODO: tracking?
    // TODO: biller?

    const imageAltText = `Join ${state.root.$site}.com`;

    return (
        <StyledPage className="page">
            <PageBackgroundStyle />
            <div className="card-image">
                <CardImage image={picture} alt={imageAltText} />
            </div>
            <div className="data">
                <h1>Create Your Account</h1>
                {error?.type === `generic` && <div className="error">{error.message}</div>}
                <form onSubmit={attemptJoin}>
                    <div className="entry">
                        <label className="user-data">
                            Enter your email:
                            <input
                                type="email"
                                name="email"
                                placeholder="youremail@youremail.com"
                                required
                                autoComplete="email"
                            />
                        </label>
                        {error?.type === `email` && <div className="error">{error.message}</div>}
                    </div>
                    <div className="entry">
                        <label className="user-data">
                            Password:
                            <input
                                type="password"
                                name="password"
                                placeholder="0-9 and letters"
                                required
                                autoComplete="new-password"
                                pattern="^[a-zA-Z0-9]{6,}$"
                                title="Minimum 6, 0-9 and letters"
                            />
                        </label>
                        {error?.type === `password` && <div className="error">{error.message}</div>}
                    </div>
                    <h1>Choose Your Membership</h1>
                    {packages.map(MembershipOption)}
                    <input type="submit" value="CONTINUE >>" className="action" />
                </form>
                <div className="info">
                    <div>
                        <img className="paypal" src={imagePaypal} alt="Secure Payment" />
                        <h3>safe payments and data privacy</h3>
                    </div>
                    <div>
                        <img src={image4k} alt="4K resolution" />
                        <h3>video up to 4k quality to download or stream</h3>
                    </div>
                    <div>
                        <img src={imageResponsive} alt="Responsive design" />
                        <h3>watch on all your devices</h3>
                    </div>
                </div>
                <p className="privacy">
                    To protect your privacy, this website does not store your personal or credit
                    card information. Transactions are secured by Comodo Secure, and processed by a
                    licensed VISA, Mastercard, JCB, DISCOVER, and DINERS CLUB, payment processor.
                    <br />
                    <br />
                    Trial memberships allow you to browse the members area, but restricted to
                    viewing only our latest update. This will provide you a fair example of what a
                    full membership entails. For your convenience, trial memberships are
                    automatically upgraded to full at the end of the trial period, unless you
                    cancel. A full membership costs what you see on this page. There are no tricks
                    like other sites that charge you low intro rates, then raise the price on you.
                </p>
            </div>
        </StyledPage>
    );

    async function attemptJoin(event) {
        event.preventDefault();
        setError(undefined);

        const formData = new FormData(event.target);
        const form = Object.fromEntries(formData.entries());
        const response = await fetch(
            `${state.root.key.api}/api/legacy/user/register?site=${state.root.$site}`,
            {
                method: `post`,
                headers: {
                    Accept: `application/json`,
                    'Content-Type': `application/json`,
                },
                body: JSON.stringify({
                    email: form.email,
                    password: form.password,
                    package: form.package,
                }),
            }
        );

        if (response.ok) {
            const resp = await response.json();
            if (resp.error) {
                switch (resp.error.code) {
                    case `auth/email-already-exists`:
                        setError({
                            type: `email`,
                            message: `The email address you entered is already in use`,
                        });
                        return;
                    case `auth/email-inactive`:
                        setError({
                            type: `email`,
                            message: `The email address you entered is already in use. Please login to activate your account`,
                        });
                        return;
                    case `auth/invalid-password`:
                        setError({
                            type: `password`,
                            // TODO: Get the correct code back
                            message: `The password you entered is invalid`,
                            // message: `The password you entered is invalid. ${error.message}`
                        });
                        return;
                    default:
                        console.error(
                            `An unknown error occurred while a user tried to join`,
                            error
                        );
                        setError({
                            type: `generic`,
                            message: `An error occurred while trying to join. Please contact support`,
                        });
                        return;
                }
            }

            window.location.href = resp.link;
        }
    }
}
