import React from 'react';
import CardError from '../component/card/error.jsx';

import image404 from '../artifacts/404.jpg';

export default function PageNotFound({ state }) {
    return (
        <div className="page">
            <CardError
                state={state.root}
                title="Oops!"
                info="Wrong hole baby! (We can't find what you're looking for!)"
                image={<img src={image404} alt="Not found face" />}
            />
        </div>
    );
}
