import createAssetService from '../../argonath/asset.js';
import createVideoService from '../../argonath/video.js';
import createTextService from '../../argonath/text.js';
import createBannerService from '../../argonath/banner.js';
import createHomepageFeaturesService from '../../argonath/homepage-features.js';
import { createVideoPreparer } from './videos.js';
import { createModelPreparer } from './models.js';
import promiseTree from '../../../lib/promise-tree.js';

import createState from '../state.js';
import placeholder from '../../../lib/placeholder.js';

const LOADING_VIDEO = {
    videoId: `video-loading`,
    title: ``,
    loading: true,
    models: [
        {
            modelId: `model-loading`,
            name: ``,
            loading: true,
            image: {
                portraight: {
                    x1: placeholder(8, 9),
                },
            },
        },
    ],
    description: {
        short: ``,
        long: ``,
    },
    homeImage: {
        portraight: {
            x1: placeholder(4, 5),
        },
        landscape: {
            x1: placeholder(64, 25),
        },
    },
};

const LOADING_FEATURE_VIDEO = {
    loading: true,
    videoId: `feature-video-loading`,
    title: `Loading video feature`,
    image: {
        landscape: {
            x1: placeholder(64, 25),
        },
    },
};

const LOADING_FEATURE_MODEL = {
    loading: true,
    modelId: `feature-model-loading`,
    title: `Loading model feature`,
    image: {
        landscape: {
            x1: placeholder(64, 25),
        },
    },
};

const LOADING = [loadingVideo(0), loadingVideo(1), loadingVideo(2), loadingVideo(3)];
const LOADING_FEATURES = [
    {
        title: `featurelist-1`,
        content: [loadingVideoFeature(0), loadingVideoFeature(1), loadingVideoFeature(2)],
    },
    {
        title: `featurelist-2`,
        content: [loadingVideoFeature(3), loadingVideoFeature(4), loadingVideoFeature(5)],
    },
    {
        title: `featurelist-3`,
        content: [loadingModelFeature(0), loadingModelFeature(1), loadingModelFeature(2)],
    },
];

// TODO: Change this to loading once this is coming from a datasource
const LOADING_NEXT = {
    videoId: `video-loading`,
    models: [
        {
            modelId: `model-loading`,
            name: ``,
            loading: true,
            image: {
                portraight: {
                    x1: placeholder(8, 9),
                },
            },
        },
    ],
    loading: true,
    description: {
        short: ``,
    },
    homeImage: {
        portraight: {
            x1: placeholder(4, 5),
        },
        landscape: {
            x1: placeholder(64, 25),
        },
    },
};

const DEFAULT_NEWS = {
    name: ``,
    value: ``,
};
const DEFAULT_OUR_STORY = {
    name: ``,
    value: ``,
};
const DEFAULT_SPECIAL = {
    portraight: {
        x1: placeholder(3, 5),
    },
    landscape: {
        x1: placeholder(16, 9),
    },
};

export default function createHomeState({
    root,
    values: {
        recent = LOADING,
        features = LOADING_FEATURES,
        next = LOADING_NEXT,
        news = DEFAULT_NEWS,
        ourStory = DEFAULT_OUR_STORY,
        special = DEFAULT_SPECIAL,
        specialLink = `/join`,
    } = {},
}) {
    const { serve } = createAssetService(root);
    const { list: videoList, next: videoNext } = createVideoService(root);
    const { find: textFind } = createTextService(root);
    const { list: bannerList } = createBannerService(root);
    const { homepageFeatures } = createHomepageFeaturesService(root);
    const prepareVideo = createVideoPreparer(root);
    const prepareModel = createModelPreparer(root);
    const state = createState({
        root,
        values: {
            $page: `home`,
            recent,
            features,
            next,
            title: ``,
            news,
            ourStory,
            special,
            specialLink,
        },
        derived: {
            latest: {
                from: [`recent`],
                combine: state => state.recent[0],
            },
            recentModels: {
                from: [`recent`],
                // TODO: It would be better if this could somehow return
                //  the same array every time....
                combine: state =>
                    state.recent.slice(1, 4).map(video => ({
                        ...video.models[0],
                        videoId: video.videoId,
                    })),
            },
        },
        createActions,
    });
    return state;

    function createActions(state) {
        return { load };

        async function load() {
            // If we've alreadt loaded do nothihg
            if (state.recent !== LOADING) {
                return;
            }

            try {
                const banner = (await bannerList()).reduce((c, n) => {
                    const now = new Date();
                    const current = new Date(c.start);
                    const next = new Date(n.start);
                    // if there is a current
                    // and next is later than now (next is in future) => current
                    // or current is later than next (current is newer) => current
                    // else => next
                    return c && (next - now > 0 || current - next > 0) ? c : n;
                });

                state.special = await promiseTree({
                    portraight: {
                        x1: serveImage(`/graphics/homepage-${banner.name}-hero-small.jpg`),
                    },
                    landscape: {
                        x1: serveImage(`/graphics/homepage-${banner.name}-hero-big.jpg`),
                    },
                });
                state.specialLink = banner.link || `/join`;

                const recent = await videoList(
                    `release`, // order by release
                    true, // desc
                    undefined, // after
                    4 // limit
                );

                const videos = await Promise.all(
                    recent.map(
                        // Prepare the video without the detailed CDN data
                        video => prepareVideo(video, `home`)
                    )
                );

                state.recent = videos;

                const features = await homepageFeatures();

                state.features = await Promise.all(
                    features.map(async feature => ({
                        ...feature,
                        content: await Promise.all(
                            feature.content.map(item => {
                                if (item.videoId) {
                                    return prepareVideo(item, `videos`);
                                }
                                if (item.talentId) {
                                    return prepareModel(item);
                                }
                                return item;
                            })
                        ),
                    }))
                );
                const nextVideo = await videoNext();
                if (nextVideo) {
                    state.next = await prepareVideo(nextVideo, `home`);
                } else {
                    state.next = undefined;
                }

                state.news = await textFind(`news`);
                state.ourStory = await textFind(`our-story`);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function serveImage(path) {
        if (!path) {
            return undefined;
        }
        const info = await serve(path);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return undefined;
        }
        return info && info.serve && info.serve.uri;
    }
}

function loadingVideo(index) {
    return {
        ...LOADING_VIDEO,
        videoId: `${LOADING_VIDEO.videoId}-${index}`,
        models: LOADING_VIDEO.models.map((model, modelIndex) => ({
            ...model,
            modelId: `${model.modelId}-${index}-${modelIndex}`,
        })),
    };
}

function loadingVideoFeature(index) {
    return {
        ...LOADING_FEATURE_VIDEO,
        videoId: `${LOADING_FEATURE_VIDEO.videoId}-${index}`,
    };
}

function loadingModelFeature(index) {
    return {
        ...LOADING_FEATURE_MODEL,
        modelId: `${LOADING_FEATURE_MODEL.modelId}-${index}`,
    };
}
