import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Navigate from '../container/navigate.jsx';
import Cards from '../container/cards.jsx';
import CardImageTitled from '../component/card/image-titled.jsx';
import { Spinner } from '../component/element/spinner.jsx';

const MAX_IMAGES = 10;
const LOAD_PADDING = 800;

const StyledPage = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const Title = styled.h1`
    font-size: 1.5em;
    text-align: center;
    text-transform: uppercase;
`;

export default function PageModels({ state }) {
    const models = state.useModels();
    const contentRef = useRef(null);

    useEffect(() => {
        document.addEventListener(`scroll`, onScroll);
        return () => document.removeEventListener(`scroll`, onScroll);

        function onScroll() {
            if (hasReachedBottom(contentRef)) {
                state.next();
            }
        }
    }, [models]);

    return (
        <StyledPage className="page" ref={contentRef}>
            <Title className="heading">All Models</Title>
            <Cards mobileColumns={2}>
                {models.map((model, index) => (
                    <Navigate
                        state={state.root}
                        key={model.modelId}
                        href={`/models/${model.modelId}`}
                    >
                        <CardImageTitled
                            title={model.name}
                            image={model.image.portraight}
                            blurTitle={model.loading}
                            lazy={index >= MAX_IMAGES}
                        />
                    </Navigate>
                ))}
            </Cards>
            {models[models.length - 1]?.isLast ? null : <Spinner />}
        </StyledPage>
    );

    function hasReachedBottom(ref) {
        if (ref.current) {
            const bounds = ref.current.getBoundingClientRect();
            const threshold = window.innerHeight + LOAD_PADDING;
            const reached = bounds.bottom <= threshold;
            return reached;
        } else {
            return false;
        }
    }
}
