import assert from 'assert';
import React from 'react';

export default function PageStaticUsc2257({ state }) {
    const email = state.root.email.$contact;
    assert(email, `email is required`);
    const mailTo = `mailto:${email}`;
    return (
        <div className="page">
            <h1>18 U.S.C. 2257 and 2257A Record-Keeping Requirements Compliance Statement</h1>
            <p>
                In compliance with U.S.C. Title 18, Sections 2257 and 2257A and related regulations,
                all performers, models, actors, actresses and other persons who appear in any visual
                depiction of actual or simulated sexually explicit conduct appearing on or otherwise
                contained on this website are or were 18 years of age or older at the time of the
                creation of such depictions. Records required to be maintained pursuant to U.S.C.
                Title 18, Sections 2257 and 2257A are located at and maintained by:
            </p>
            <p>
                Please direct questions pertaining to content on this website to{` `}
                <a href={mailTo}>{email}</a>
            </p>
            <p>
                Custodian of Records c/o Extasis Group 25/F Workington Tower 78 Bonham Strand Sheung
                Wan, HK
            </p>
        </div>
    );
}
