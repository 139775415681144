import assert from 'assert';
import { ApolloLink } from '@apollo/client';

export default function createAuthLink() {
    return new ApolloLink(async (operation, next) => {
        const { state } = operation.getContext();
        assert(state, `state MUST be set on the context`);

        try {
            return await makeRequest();
        } catch (error) {
            throw error;
        }

        function makeRequest() {
            operation.setContext(previous => ({
                ...previous,
                headers: {
                    ...(previous && previous.headers),
                },
            }));
            return next(operation);
        }
    });
}
