import { gql } from '@apollo/client';
import { getClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createBannerService(state) {
    return {
        list,
    };

    function list() {
        return handleError(() => doList());
    }

    async function doList() {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query ListBanners {
                    banner {
                        list {
                            result {
                                name
                                link
                                start
                            }
                        }
                    }
                }
            `,
        });
        return result.data.banner.list.result;
    }
}
