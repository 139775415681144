import React from 'react';
import styled from 'styled-components';
import constant from '../../constants.js';

const StyledCard = styled.div`
    position: relative;

    @media (max-width: ${constant.size.small}) {
        display: flex;
        flex-direction: column;
    }

    img {
        display: block;
        width: 100%;
    }

    .info {
        margin: 0;
        color: var(--color-card-text);
        background-color: rgba(var(--color-card-rgb), 0.8);
        padding: 2em;
        padding-left: 4em;
        padding-right: 4em;
        text-align: center;
        border: 1px solid var(--color-border);
        text-transform: uppercase;
    }

    @media (min-width: ${constant.size.small}) {
        .info {
            position: absolute;
            left: 5%;
            top: 3%;
        }
    }

    @media (max-width: ${constant.size.small}) {
        .info {
            position: relative;
        }
        .info:before {
            // This is the arrow
            content: ' ';
            position: absolute;
            left: 15%;
            top: -1.5em;
            width: 0em;
            height: 0em;
            border: 0.75em solid transparent;
            border-bottom: 0.75em solid var(--color-card);
        }
    }

    @media (min-width: ${constant.size.small}) and (max-width: ${constant.size.standard}) {
        .info {
            font-size: 0.8em;
        }
    }

    h2,
    h3 {
        text-transform: uppercase;
    }

    h2 {
        font-weight: 800;
    }

    h3 {
        border-top: 1px solid var(--color-border);
        padding-top: 1em;
    }

    @media (max-width: ${constant.size.small}) {
        h3 {
            padding-top: 0.5em;
        }

        h4 {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
`;

export default function CardImageInfo({ title, info, source }) {
    return (
        <StyledCard className="card model border">
            <picture>
                <source
                    srcSet={source.landscape.x1}
                    media={`(min-width: ${constant.size.small})`}
                />
                <source srcSet={source.landscape.x1} media={`(min-aspect-ratio: 1 / 1)`} />
                <source srcSet={source.portraight.x1} />
                <img src={source.landscape.x1} alt={title} />
            </picture>
            <div className="card info">
                <h1>{title}</h1>
                {info.map((entry, index) => (
                    <div key={index} className="entry">
                        <h3>{entry.name}</h3>
                        <h4>{entry.value}</h4>
                    </div>
                ))}
            </div>
        </StyledCard>
    );
}
