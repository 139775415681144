import { gql } from '@apollo/client';
import DataLoader from 'dataloader';
import { getClient, getAuthenticatedClient } from './client/index.js';
import handleError from './handle-error.js';

const MAX_CONCURRENT = 100;

const INFO = {
    serve: `
        type
        uri
    `,
    download: `
        type
        uri
    `,
    stream: `
        uri
        token
        type
    `,
};

export default function createAssetService(state) {
    const serveLoader = new DataLoader(createLoaderHandler(getClient, serveAsset));
    const streamLoader = new DataLoader(createLoaderHandler(getClient, streamAsset));
    const downloadLoader = new DataLoader(createLoaderHandler(getClient, downloadAsset));

    const authServeLoader = new DataLoader(createLoaderHandler(getAuthenticatedClient, serveAsset));
    const authStreamLoader = new DataLoader(
        createLoaderHandler(getAuthenticatedClient, streamAsset)
    );
    const authDownloadLoader = new DataLoader(
        createLoaderHandler(getAuthenticatedClient, downloadAsset)
    );

    return {
        serve,
        stream,
        download,
    };

    function serve(key, member) {
        if (member) {
            return authServeLoader.load(key);
        } else {
            return serveLoader.load(key);
        }
    }
    function stream(key, member) {
        if (member) {
            return authStreamLoader.load(key);
        } else {
            return streamLoader.load(key);
        }
    }
    function download(key, member) {
        if (member) {
            return authDownloadLoader.load(key);
        } else {
            return downloadLoader.load(key);
        }
    }

    function createLoaderHandler(getClient, handler) {
        return path => handler(path, find, batch);

        async function find(path, type) {
            const client = await getClient(state);
            const queryResult = await client.query({
                query: gql`
                    query FindAssetQuery($path: String!) {
                        asset {
                            find(input: { path: $path }) {
                                result {
                                    path
                                    mime
                                    size
                                    ${type} {
                                        ${INFO[type]}
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: { path },
            });
            const found = queryResult.data.asset.find.result;
            if (!found) {
                throw new Error(`Missing asset at "${path}"`);
            }
            return found;
        }

        async function batch(paths, type) {
            const client = await getClient(state);
            const queryResult = await client.query({
                query: gql`
                    query BatchFindAssetQuery($paths: [String!]!) {
                        asset {
                            batch(input: { paths: $paths }) {
                                result {
                                    path
                                    mime
                                    size
                                    ${type} {
                                        ${INFO[type]}
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: { paths },
            });
            const found = queryResult.data.asset.batch.result;
            return found;
        }
    }

    async function downloadAsset(path, find, batch) {
        if (Array.isArray(path)) {
            return unique(path, items => handleError(() => batch(items, `download`)));
        } else {
            return handleError(() => find(path, `download`));
        }
    }

    async function serveAsset(path, find, batch) {
        if (Array.isArray(path)) {
            return unique(path, items => handleError(() => batch(items, `serve`)));
        } else {
            return handleError(() => find(path, `serve`));
        }
    }

    async function streamAsset(path, find, batch) {
        if (Array.isArray(path)) {
            return unique(path, items => handleError(() => batch(items, `stream`)));
        } else {
            return handleError(() => find(path, `stream`));
        }
    }

    async function unique(path, exec) {
        const unq = path.filter((p, idx) => path.indexOf(p) === idx);
        const result = await split(unq, MAX_CONCURRENT, exec);
        // const result = await exec(unq);
        return path.map(p => result[unq.indexOf(p)]);

        async function split(arr, size, exec) {
            const arrs = [];
            for (let index = 0; index < arr.length; index = index + size) {
                arrs.push(arr.slice(index, index + size));
            }
            const result = await Promise.all(arrs.map(exec));
            return result.flat();
        }
    }
}
