import React from 'react';
// import loadable from '@loadable/component';
// TODO: Re-introduce loadable
// const SymbolDollar = loadable(() => import('./dollar.jsx'));
import SymbolDollar from './dollar.jsx';

export default function Currency(props) {
    const { symbol } = props;
    switch (symbol) {
        case `$`:
            return <SymbolDollar>{props.children}</SymbolDollar>;
        default:
            throw new Error(`No Currency handler for symbol "${symbol}" defined`);
    }
}
