export default async function handleErrors(exec) {
    try {
        return await exec();
    } catch (error) {
        throw extractGraphqlError(error);
    }
}

function extractGraphqlError(error) {
    if (!Array.isArray(error.graphQLErrors)) {
        return error;
    }
    const gqlError = error.graphQLErrors[0];
    if (!gqlError) {
        return error;
    }

    if (!gqlError.extensions) {
        return error;
    }

    if (!gqlError.extensions.exception) {
        Object.assign(error, gqlError.extensions);
        return error;
    }

    error = new Error(error.message);
    error.code = gqlError.extensions.exception.code;
    return error;
}
