import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Player from '../component/element/player/index.jsx';
import Navigate from '../container/navigate.jsx';
import constants from '../constants.js';
import CardDownload from '../component/card/download.jsx';
import Gallery from '../container/gallery.jsx';
import constant from '../constants.js';

import NotFound from './404.jsx';

const StyledPage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .gallery-image {
        padding: 0;
        margin: 0.2em;
        border: none;
    }

    @media (max-width: ${constant.size.medium}) {
        .gallery-image {
            padding: 0;
            margin: 0.3em;
            border: none;
        }
    }

    .gallery-image > img {
        display: block;
        width: 100%;
    }

    .ancillary {
        display: flex;
        margin-top: 1em;
        margin-bottom: 1em;
        width: 100%;
    }

    .ancillary > div {
        box-sizing: border-box;
    }

    #photo {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: fixed;
        top: var(--height-header);
        left: 0;
        right: 0;
        bottom: 0;
        padding: 2em;
        text-align: center;
    }

    @media (max-width: ${constant.size.medium}) {
        #photo {
            padding: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    #photo .overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-overlay);
    }

    #photo > div.image {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1000;
        height: 100%;
        position: relative;
    }

    #photo > div.image > .close {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
        z-index: 999;
    }

    #photo > div.image > .close-button {
        position: absolute;
        right: 0.1em;
        top: 0.1em;
        cursor: pointer;
        color: var(--color-overlay-text);
        font-weight: 800;
        font-size: 2em;
    }

    #photo .image-gallery-slide img {
        display: block;
        margin: 1em;
        margin-left: auto;
        margin-right: auto;
        border: 0.2em solid var(--color-primary);
        border-radius: 5px;
        object-fit: contain;
        z-index: 1000;

        max-height: 80vh;
        max-width: 80vw;

        width: 100%;
        height: auto;

        @media (min-aspect-ratio: 1/1) {
            width: auto;
            height: 100%;
        }
    }

    #photo > div.nav {
        flex-grow: 0;
        color: var(--color-primary);
        font-size: 10em;
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1000;
        user-select: none;
        border-radius: 5px;
        transition: background-color 0.2s;
    }

    #photo > div.nav:hover {
        background-color: rgba(var(--color-action-active-rgb), 0.5);
    }

    .cloudflare-player {
        height: 0;
        padding-top: 56.25%;
        width: 100%;
    }

    .model {
        color: var(--color-action-active);
    }

    @media (max-width: ${constants.size.small}) {
        .ancillary {
            flex-direction: column;
            justify-content: center;
        }

        .ancillary .info {
            margin-left: 1em;
            margin-right: 1em;
        }
    }

    @media not all and (max-width: ${constants.size.small}) {
        .ancillary {
            flex-direction: row;
        }

        .ancillary > .half {
            width: 50%;
        }
    }
`;

export default function PageVideo({ state, user }) {
    const video = state.useVideo();
    const [vid, setVid] = useState({ loading: true });
    const userHasAccess =
        user &&
        (user.type === `standard` || (user.type === `trial` && user.trialCredit === video.videoId));

    useEffect(() => {
        (async () => {
            let controller;
            try {
                if (!vid.token && userHasAccess && video && video.videoId) {
                    controller = new AbortController();
                    const response = await fetch(
                        `${state.root.key.api}/api/legacy/data/video/${video.videoId}?site=${state.root.$site}`,
                        {
                            signal: controller.signal,
                            credentials: `include`,
                        }
                    );
                    if (response.ok) {
                        const resp = await response.json();
                        setVid({
                            token: resp.token,
                            downloads: resp.downloads,
                            loading: false,
                        });
                        return;
                    }
                }
            } catch (error) {
                console.log(error);
            }
            return () => {
                controller.abort();
            };
        })();
    }, [userHasAccess, state.root.key.api, state.root.$site, vid, video]);

    if (!video) {
        return <NotFound />;
    }

    const modelsText = video.models.map((model, index, arr) => {
        const modelHref = `/models/${model.modelId}`;
        let Title;
        if (index === 0) {
            if (arr.length === 1) {
                Title = () => <span>model: </span>;
            } else {
                Title = () => <span>models: </span>;
            }
        } else {
            Title = () => null;
        }
        const Spacer = () => (index < arr.length - 1 ? <span> / </span> : null);
        return (
            <span key={index}>
                <Title />
                <Navigate state={state} key={model.modelId} href={modelHref} className="model">
                    {model.name}
                </Navigate>
                <Spacer />
            </span>
        );
    });

    // TODO: { video.thumbs && <video id='thumbs' src={video.thumbs} /> }

    const titleStyle = {};
    if (video.loading) {
        // TODO: Fix this... need vars!
        titleStyle.color = `transparent`;
        titleStyle.textShadow = `0 0 10px rgba(0,0,0,0.5)`;
    }

    return (
        <StyledPage className="page">
            <Player
                userHasAccess={userHasAccess}
                src={vid.token}
                loading={video.loading || vid.loading}
                poster={video.poster}
                controls
                width="100%"
                thumbnails={video.thumbs}
            />
            <div className="ancillary">
                <div className={user && vid.downloads ? `info half` : `info`} style={titleStyle}>
                    <h1>{video.title}</h1>
                    <h3>
                        {modelsText} {modelsText && ` / `} duration:{` `}
                        <Duration duration={video.duration} />
                    </h3>
                    <p>{video.description.long}</p>
                </div>
                {user && vid.downloads && <CardDownload downs={vid.downloads} state={state} />}
            </div>
            <Gallery state={state} />
        </StyledPage>
    );

    function Duration(props) {
        const { duration } = props;
        if (typeof duration === `string`) {
            return <span>{String(duration)}</span>;
        }

        const seconds = duration % 60;
        const minutes = ((duration - seconds) / 60) % 60;
        const hours = duration - seconds - minutes * 60;

        const parts = [pad(minutes), pad(seconds)];
        if (hours > 0) {
            parts.unshift(pad(hours));
        }

        const durationText = parts.join(`:`);
        return <span>{durationText}</span>;
    }

    function pad(value) {
        value = String(value);
        if (value.length < 2) {
            return `0${value}`;
        } else {
            return value;
        }
    }
}
