import React from 'react';
import styled from 'styled-components';
import SearchResults from '../component/search/results.jsx';

const StyledPage = styled.div`
    position: relative;

    h1 {
        text-align: center;
    }

    .page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .search-results {
    }
`;

export default function PageSearchResults({ state }) {
    return (
        <StyledPage>
            <div className="page">
                <h1 className="heading">Results</h1>
                <SearchResults className="search-results" state={state.root} />
            </div>
        </StyledPage>
    );
}
