import assert from 'assert';
import createAssetService from '../../argonath/asset.js';
import createVideoService from '../../argonath/video.js';
import createState from '../state.js';
import promiseTree from '../../../lib/promise-tree.js';
import placeholder from '../../../lib/placeholder.js';
import { createVideoPreparer } from './videos.js';

const VIDEO_SIZES = [`4k`, `1080`, `720`, `360`];

const LOADING = {
    loading: true,
    models: [],
    duration: 0,
    image: {
        portraight: {
            x1: placeholder(4, 5),
        },
        landscape: {
            x1: placeholder(64, 25),
        },
    },
    poster: {
        x1: placeholder(16, 9),
    },
    downloads: VIDEO_SIZES.map(size => ({
        title: `${size} - ...`,
        uri: false,
    })),
    gallery: Array(20)
        .fill(0)
        .map((v, index) => {
            return {
                thumb: placeholder(2, 3),
                full: placeholder(2, 3),
            };
        }),
};

export default function createVideoState({ root, values: { video, photoIndex, videoId } = {} }) {
    if (!videoId && video) {
        videoId = video.videoId;
    }
    assert(videoId, `videoId or video is required`);
    const loadingVideo = {
        ...LOADING,
        title: root.$catchPhrase,
        description: {
            short: root.$catchPhrase,
            long: root.$catchPhrase,
        },
        // The id needs to match for pathing
        videoId,
    };
    video = video || loadingVideo;
    const prepareVideo = createVideoPreparer(root);
    const { find: findVideo } = createVideoService(root);

    return createState({
        root,

        values: {
            $page: `video`,
            video,
            photoIndex: photoIndex === undefined ? -1 : photoIndex,
        },

        derived: {
            title: {
                from: [`video`],
                combine: state => state.video.title,
            },
            downloads: {
                from: [`video`],
                combine: prepareDownloads,
            },
            gallery: {
                from: [`video`],
                combine: prepareGallery,
            },
        },

        createActions,
    });

    function createActions(state) {
        let loading, videoLoading;

        return {
            load,
        };

        function load() {
            if (!videoId) {
                root.page.notFound();
                return;
            }
            if (!loading) {
                loading = doLoad();
            }
            return loading;
        }

        async function doLoad() {
            const video = await loadVideo();
            if (!video) {
                root.page.notFound();
            }
        }

        async function loadVideo() {
            if (!videoId) {
                return undefined;
            }
            videoLoading = videoLoading || findVideo(videoId);
            const video = await videoLoading;
            if (!video) {
                return undefined;
            }

            // TODO: Extract downloads in the same way as gallery
            // TODO: Why is this running multiple times...? (Appears to be running 3 times!?)
            const prepared = await prepareVideo(video, `video`);

            // TODO: We should be createing a state from the video so
            //  we can subscribe to values within it

            state.video = prepared;
            return video;
        }
    }

    function prepareGallery(state) {
        // TODO: We don't need the full images immediately
        if (!state.video) {
            return [];
        }

        if (Array.isArray(state.video.gallery)) {
            return state.video.gallery;
        }

        const galleryCount = state.video.galleryCount > 0 ? parseInt(state.video.galleryCount) : 0;

        const [modelId, videoId] = state.video.videoId.split(`:`);

        // TODO: Need to either consider a better naming convention
        //  or have an asset service that can return a list or
        //  store the relevant data on the video record
        const sceneIndex = videoId.replace(/[a-z]/gi, ``);
        const { serve } = createAssetService(state);
        return promiseTree(
            Array(galleryCount)
                .fill(0)
                .map((_, index) => {
                    return {
                        thumb: serveImage(
                            `/members/models/${modelId}/scenes/${videoId}/photos` +
                                `/thumbs/${state.root.$site}-${modelId}-${sceneIndex}-${
                                    index + 1
                                }.jpg`
                        ),
                        // full: serveImage(
                        //     `/members/models/${modelId}/scenes/${videoId}/photos` +
                        //         `/${state.root.$site}-${modelId}-${sceneIndex}-${index + 1}.jpg`,
                        //     true
                        // ),
                    };
                })
        );

        async function serveImage(path, member) {
            const info = await serve(path, member);
            if (!info) {
                console.warn(`Unable to find asset info for "${path}"`);
                return;
            }
            if (info.serve && info.serve.uri) {
                return info.serve.uri;
            } else {
                return undefined;
            }
        }
    }

    async function prepareDownloads(state) {
        if (!state.video) {
            return [];
        }

        if (Array.isArray(state.video.downloads)) {
            return state.video.downloads;
        }

        const { download } = createAssetService(state);
        const [modelId, videoId] = state.video.videoId.split(`:`);
        // TODO: video does not have sizes...
        //  We need a highest quality coming out of argonath
        const downloads = VIDEO_SIZES.map(size => ({
            title: `${size}`,
            asset: downloadFile(
                `/members/streamvids/${modelId}/${modelId}-${videoId}-${size}.mp4`,
                true
            ),
        }));

        if (state.video.galleryCount > 0) {
            downloads.push({
                title: `Download Picture Set`,
                pictureSet: true,
                asset: downloadFile(
                    `/members/models/${modelId}/scenes/${videoId}/${modelId}-${videoId}-pics.zip`,
                    true
                ),
            });
        }

        const finalDownloads = await promiseTree(downloads);
        const result = finalDownloads
            .filter(download => download.asset)
            .map(downloadResult => {
                const {
                    title,
                    pictureSet,
                    asset: { size, download },
                } = downloadResult;
                const uri = download && download.uri;

                const sizeMb = Math.floor(size / 1024 / 1024);
                const titleText =
                    sizeMb > 0 && pictureSet !== true ? `${title} - ${sizeMb}MB` : title;

                return {
                    title: titleText,
                    uri,
                };
            });
        return result;

        async function downloadFile(path) {
            const info = await download(path, true);
            if (!info) {
                console.warn(`Unable to find asset info for "${path}"`);
                return undefined;
            }
            return info;
        }
    }
}
