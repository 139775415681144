import assert from 'assert';
import createPageState from './page/index.js';
import createBillerState from './biller.js';
import createDimensionState from './dimension.js';
import createInteractionState from './interaction.js';
import createSearchState from './search.js';
import createState from './state.js';

export default function createAppState({
    $site,
    $catchPhrase,
    $topic,

    key,
    user,
    page,
    email,
    dimension,
    interstitial,
}) {
    assert($site, `site MUST be supplied`);
    assert($catchPhrase, `catchPhrase MUST be supplied`);
    assert($topic, `topic MUST be supplied`);
    assert(email, `email MUST be supplied`);
    assert(email.$contact, `email.contact MUST be supplied`);
    assert(email.$support, `email.support MUST be supplied`);

    const root = createState({
        values: {
            $site,
            $topic,
            $catchPhrase,
        },
    });

    root.email = createState({
        values: {
            $support: email.$support,
            $contact: email.$contact,
        },
    });

    root.interaction = createInteractionState({
        root,
    });

    root.biller = createBillerState({
        root,
    });

    root.dimension = createDimensionState({
        root,
        values: dimension,
    });

    root.page = createPageState({
        root,
        values: page,
    });

    root.key = createState({
        root,
        values: key,
    });

    root.search = createSearchState({ root });

    Object.freeze(root);
    return root;
}
