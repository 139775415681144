import { gql } from '@apollo/client';
import { getClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createMasonryService(state) {
    return {
        list,
    };

    function list() {
        return handleError(() => listMasonry());
    }

    async function listMasonry() {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query ListMasonry {
                    siteData {
                        masonry {
                            result {
                                type
                                file
                                join
                                poster
                            }
                        }
                    }
                }
            `,
        });
        return result.data.siteData.masonry.result;
    }
}
