import React from 'react';
import styled from 'styled-components';
import Navigate from '../container/navigate.jsx';
import constant from '../constants.js';

const Styles = styled.div`
    background-color: var(--color-action);

    img {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`;

export function Banner({ state, special, specialLink }) {
    return (
        <Styles>
            <div className="page">
                <Navigate href={specialLink} state={state.root}>
                    <picture>
                        <source
                            srcSet={special.landscape.x1}
                            media={`(min-width: ${constant.size.small})`}
                        />
                        <source srcSet={special.landscape.x1} media={`(min-aspect-ratio: 1 / 1)`} />
                        <source srcSet={special.portraight.x1} />
                        <img src={special.landscape.x1} alt="Join Fit18" />
                    </picture>
                </Navigate>
            </div>
        </Styles>
    );
}
