import React from 'react';
import MasonryComponent from '../component/element/masonry.jsx';

export default function Masonry({ state, ...props }) {
    const documentWidth = state.dimension.useWidth();
    return (
        <MasonryComponent documentWidth={documentWidth} columns={props.columns} {...props}>
            {props.children}
        </MasonryComponent>
    );
}
