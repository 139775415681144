import createAssetService from '../../argonath/asset.js';
import createVideoService from '../../argonath/video.js';
import promiseTree from '../../../lib/promise-tree.js';
import createState from '../state.js';
import placeholder from '../../../lib/placeholder.js';
import mutex from '../mutex.js';

// TODO: From config
const VIDEO_SIZES = [`4k`, `1080`, `720`, `360`];
const LIMIT = 15;

const LOADING_VIDEO = {
    videoId: `video-loading`,
    models: [],
    loading: true,
    poster: {
        x1: placeholder(16, 9),
    },
    image: {
        portraight: {
            x1: placeholder(4, 5),
        },
        landscape: {
            x1: placeholder(64, 25),
        },
    },
};

const LOADING = Array(20)
    .fill(0)
    .map((ele, idx) => ({
        ...LOADING_VIDEO,
        videoId: `${LOADING_VIDEO.videoId}-${idx}`,
    }));

export default function createVideosState({ root, values: { videos } }) {
    let loading;
    if (!videos) {
        videos = loading = LOADING.map(ele => ({
            ...ele,
            title: root.$catchPhrase,
            name: root.$catchPhrase,
            description: {
                short: root.$catchPhrase,
                long: root.$catchPhrase,
            },
        }));
    }

    const prepareVideo = createVideoPreparer(root);
    const { list: listVideo } = createVideoService(root);

    // list takes (order, after, limit)

    return createState({
        root,

        values: {
            $page: `videos`,
            title: `Videos`,
            videos,
        },

        createActions,
    });

    function createActions(state) {
        return {
            load,
            next: mutex(next),
        };

        async function load() {
            if (state.videos !== loading) {
                return;
            }
            const videos = await loadFrom(undefined);
            state.videos = videos;
        }

        async function next() {
            // We don't want next to be available until after
            //  the initial load is complete
            if (state.videos === loading) {
                return;
            }

            const lastVideo = state.videos[state.videos.length - 1];
            if (!lastVideo || lastVideo.isLast) {
                return;
            }

            const after = lastVideo && lastVideo.cursor;
            const videos = await loadFrom(after);

            if (videos.length === 0 && state.videos.length > 0) {
                state.videos[state.videos.length - 1].isLast = true;
            }

            state.videos = [...state.videos, ...videos].filter(unique);
        }

        async function loadFrom(after) {
            let videos = await listVideo(
                undefined, // order
                undefined, // desc
                after, // after
                LIMIT // count
            );
            videos = await Promise.all(videos.map(video => prepareVideo(video, `videos`)));
            return videos;
        }

        function unique(ele, idx, arr) {
            const idIndex = arr.findIndex(item => item.videoId === ele.videoId);
            return idx === idIndex;
        }
    }
}

export function createVideoPreparer(state) {
    // TODO: This needs to be in it's own module
    const { serve, stream, download } = createAssetService(state);
    return (video, mode) => prepareVideo(video, mode); //.then(finalizeDownloads);

    function prepareVideo(video, mode) {
        if (!video) {
            return video;
        }

        const isVideo = mode === `video`;
        const isVideos = mode === `videos`;
        const isHome = mode === `home`;

        // const downloads = isVideo && prepareDownloads(video) || [];
        const [modelId, sceneId] = video.videoId.split(`:`);

        return promiseTree({
            ...video,
            // downloads,
            video: isVideo && streamVideo(`/members/streamvids/${modelId}/${modelId}-${sceneId}`),
            thumbs:
                isVideo &&
                serveAsset(`/members/streamvids/${modelId}/${modelId}-${sceneId}-thumbs.mp4`, true),
            image:
                isVideo ||
                (isVideos && {
                    portraight: {
                        x1: serveAsset(
                            `/members/models/${modelId}/scenes/${sceneId}/selfie-sm.jpg`
                        ),
                    },
                    landscape: {
                        x1: serveAsset(
                            `/members/models/${modelId}/scenes/${sceneId}/videothumb.jpg`
                        ),
                    },
                }),
            homeImage: isHome && {
                portraight: {
                    x1: serveAsset(`/members/models/${modelId}/scenes/${sceneId}/homethumb-sm.jpg`),
                },
                landscape: {
                    x1: serveAsset(
                        `/members/models/${modelId}/scenes/${sceneId}/homethumb-big.jpg`
                    ),
                },
            },
            poster: isVideo && {
                x1: serveAsset(`/members/models/${modelId}/scenes/${sceneId}/videothumb.jpg`),
            },
            models: prepareModels(video.talent),
        });
    }

    function prepareDownloads(video) {
        const [modelId, videoId] = video.videoId.split(`:`);
        // TODO: video does not have sizes...
        //  We need a highest quality coming out of argonath
        const downloads = VIDEO_SIZES
            // .filter(size => video[`size${size}`])
            .map(size => ({
                title: `${size}`,
                asset: downloadFile(
                    `/members/streamvids/${modelId}/${modelId}-${videoId}-${size}.mp4`,
                    true
                ),
            }));

        if (video.galleryCount > 0) {
            downloads.push({
                title: `Download Picture Set`,
                pictureSet: true,
                asset: downloadFile(
                    `/members/models/${modelId}/scenes/${videoId}/${modelId}-${videoId}-pics.zip`,
                    true
                ),
            });
        }

        return downloads;
    }

    function prepareModels(talent) {
        if (!talent) {
            return [];
        }

        return talent
            .filter(entry => entry.type === `MODEL`)
            .map(talent => {
                const { talentId, ...rest } = talent.talent;
                return {
                    modelId: talentId,
                    ...rest,
                    image: {
                        portraight: {
                            x1: serveAsset(`/members/models/${talentId}/profile-sm.jpg`),
                        },
                        landscape: {
                            x1: serveAsset(`/members/models/${talentId}/profile-big.jpg`),
                        },
                    },
                };
            });
    }

    async function serveAsset(path, member) {
        const info = await serve(path, member);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return;
        }
        if (info.serve && info.serve.uri) {
            return info.serve.uri;
        } else {
            return undefined;
        }
    }

    async function streamVideo(path) {
        const info = await stream(path, true);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return undefined;
        }
        return info.stream;
    }

    async function downloadFile(path) {
        const info = await download(path, true);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return undefined;
        }
        return info;
    }
}
