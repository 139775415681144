import { gql } from '@apollo/client';
import { getClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createVideoService(state) {
    return {
        find,
        next,
        list,
    };

    function find(videoId) {
        return handleError(() => doFind(videoId));
    }

    function next() {
        return handleError(() => doNext());
    }

    function list(...args) {
        return handleError(() => doList(...args));
    }

    async function doFind(videoId) {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query FindVideo($videoId: ID!) {
                    video {
                        find(input: { videoId: $videoId }) {
                            result {
                                videoId
                                title
                                duration
                                galleryCount
                                description {
                                    short
                                    long
                                }
                                talent {
                                    type
                                    talent {
                                        talentId
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables: { videoId },
        });
        return result.data.video.find.result;
    }

    async function doNext() {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query FindNextVideo {
                    video {
                        next {
                            result {
                                videoId
                                title
                                duration
                                description {
                                    short
                                    long
                                    coming
                                }
                                talent {
                                    type
                                    talent {
                                        talentId
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            `,
        });
        return result.data.video.next.result;
    }

    async function doList(order, desc, after, limit) {
        const client = await getClient(state);
        if (order) {
            order = [
                {
                    field: order,
                    desc,
                },
            ];
        }

        const result = await client.query({
            query: gql`
                query ListVideo($order: [OrderEntry!], $after: ID, $limit: Int) {
                    video {
                        list(input: { order: $order, after: $after, first: $limit }) {
                            result {
                                edges {
                                    node {
                                        videoId
                                        title
                                        duration
                                        description {
                                            short
                                            long
                                            home
                                        }
                                        talent {
                                            type
                                            talent {
                                                talentId
                                                name
                                            }
                                        }
                                    }
                                    cursor
                                }
                                pageInfo {
                                    hasNextPage
                                }
                            }
                        }
                    }
                }
            `,
            variables: { order, after, limit },
        });
        const connection = result.data.video.list.result;
        return connection.edges.map((edge, idx, arr) => ({
            ...edge.node,
            cursor: edge.cursor,
            isLast: idx === arr.length - 1 && !connection.pageInfo.hasNextPage,
        }));
    }
}
