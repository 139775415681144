import React from 'react';
import styled from 'styled-components';
import placeholder from '../../../lib/placeholder.js';

// TODO: Re-implement loadable
// const PlayerCloudflare = loadable(() => import('./cloudflare.jsx'));
import PlayerCloudflare from './cloudflare.jsx';
// import PlayerVideoJs from './videojs.jsx';

const UnavailableStyle = styled.a`
    position: relative;
    width: 100%;
    .play {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            width: 30%;
            height: 30%;
        }
    }
`;

const LoadingStyle = styled.div`
    position: relative;
    width: 100%;
    .spinner-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .spinner {
        position: relative;
        width: 80px;
        height: 80px;
    }

    .spinner div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: var(--color-spinner);
        animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .spinner div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }
    .spinner div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }
    .spinner div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes spinner {
        0% {
            top: 8px;
            height: 64px;
        }
        50%,
        100% {
            top: 24px;
            height: 32px;
        }
    }
`;

export default function VideoPlayer(props) {
    // TODO: When server side rendering we should always render the img
    // TODO: We don't even want to load the cloudlfare player!
    const { userHasAccess, src, loading, width, height, controls, poster, thumbnails, event } =
        props;
    const image = poster?.x1 || placeholder(width, height);
    if (!userHasAccess) {
        return (
            <UnavailableStyle href="#lockdown">
                <img src={image} width={width} height={height} />
                <div className="play">
                    <svg viewBox="0 0 48 48" fill="#fff">
                        <path d="M14 15.06V33a2 2 0 002.93 1.77l17.09-8.97a2 2 0 000-3.54L16.93 13.3A2 2 0 0014 15.06z"></path>
                    </svg>
                </div>
            </UnavailableStyle>
        );
    }
    if (loading) {
        return (
            <LoadingStyle>
                <img src={image} width={width} height={height} />
                <div className="spinner-container">
                    <div className="spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </LoadingStyle>
        );
    }

    return <PlayerCloudflare width={width} controls={controls} poster={image} src={src} />;
}
