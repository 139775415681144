import assert from 'assert';
import { ReplaySubject } from 'rxjs';
import valueEquality from 'lodash.isequal';
import useObservable from '../use-observable.js';

const NAME_SEPARATOR = /\.+(.*)/;

export function addStateValue({
    state,
    name,
    value: initialValue,
    transform = identity,
    isEqual = equality,
}) {
    // Static value
    if (name.startsWith(`$`)) {
        defineReadOnlyProperty(state, name, initialValue);
        return;
    }

    let value;
    const subject$ = new ReplaySubject(1);
    subject$.next(undefined);
    const nameObserver = buildObserverName(name);
    const nameUseObserver = buildUseName(name);
    set(initialValue);

    Object.defineProperties(state, {
        [name]: {
            configurable: true,
            enumerable: true,
            get: () => value,
            set,
        },
        [nameObserver]: {
            configurable: true,
            writable: true,
            value: subject$,
        },
        [nameUseObserver]: {
            configurable: true,
            writable: true,
            // eslint-disable-next-line react-hooks/rules-of-hooks
            value: () => useObservable(subject$, value),
        },
    });

    function set(val) {
        const transformed = transform(val);
        if (!isEqual(transformed, value)) {
            value = transformed;
            subject$.next(value);
        }
        return val;
    }
}

export function addDerivedValue({
    state,
    name,
    value,
    from,
    group,
    combine = defaultCombine,
    isEqual = valueEquality,
}) {
    if (group === undefined) {
        group = from.length > 1;
    }

    assert(name.startsWith(`$`) === false, `Only static values may start with $`);
    let immediate;
    assert(
        hasAllDependencies({ state, dependencies: from }),
        `Missing the at least one of the following dependencies: ${from.join(`, `)}`
    );

    addStateValue({
        state,
        name,
        value,
        isEqual,
    });

    const { get, set: updateValue } = Object.getOwnPropertyDescriptor(state, name);
    Object.defineProperty(state, name, {
        configurable: true,
        enumerable: true,
        get,
        set,
    });

    // Trigger a change whenever values this is derived from change
    from.forEach(dependency =>
        deepSubscribe({
            state,
            handlers: onDependencyChange,
            name: dependency,
        })
    );

    // This will set the values (eventually if combine is async)
    performUpdate();

    function set() {
        throw new Error(
            `Unable to set derived value "${name}". Set one of "${from.join(
                `, `
            )} to trigger a change`
        );
    }

    async function onDependencyChange() {
        if (!group) {
            await performUpdate();
            return;
        }

        // Batch things together
        if (immediate) {
            return;
        }

        // TODO: Should this run next tick instead of immediate?
        immediate = setImmediate(async () => {
            immediate = undefined;
            await performUpdate();
        });
    }

    function performUpdate() {
        const value = combine(state, ...from);
        if (typeof value?.then === `function`) {
            return value.then(updateValue);
        } else {
            updateValue(value);
        }
    }
}

export default function createState({
    root,
    values = {},
    derived = {},
    transform = {},
    createActions = noop,
} = {}) {
    assert(!root || typeof root === `object`, `When supplied "root" MUST be an object`);
    assert(values && typeof values === `object`, `values MUST be an object`);

    const state = { toJSON };
    defineReadOnlyProperty(state, `root`, root || state);

    for (const [name, value] of Object.entries(values)) {
        addStateValue({
            name,
            value,
            state,
            transform: transform[name],
        });
    }

    const derivedKeys = Object.keys(derived);
    let processed;
    do {
        processed = false;
        for (const name of derivedKeys.slice()) {
            const hasEveryDependenncy = derived[name].from.every(dependency =>
                hasDependency({
                    state,
                    dependency,
                })
            );

            if (!hasEveryDependenncy) {
                continue;
            }
            processed = true;
            derivedKeys.splice(derivedKeys.indexOf(name), 1);

            addDerivedValue({
                state,
                name,
                ...derived[name],
            });
        }
        if (processed === false && derivedKeys.length) {
            throw new Error(
                `Circular dependency detected (Possible keys: ${derivedKeys.join(`, `)})`
            );
        }
    } while (processed);

    const actions = createActions(state);
    Object.assign(state, actions);
    return state;

    function toJSON() {
        const staticKeys = Object.keys(values || {}).filter(key => key.startsWith(`$`));
        const valueKeys = Object.keys(values || {})
            .filter(key => key.startsWith(`$`) === false)
            .filter(key => key !== `root`);
        const derivedKeys = Object.keys(derived || {});
        const actionKeys = Object.keys(actions || {});

        const subValues = Object.keys(state)
            .filter(key => key.startsWith(`$`) === false)
            .filter(key => key.endsWith(`$`) === false)
            // TODO: Generalise this when you get a chance
            .filter(key => key === `user` || key.startsWith(`use`) === false)
            .filter(key => key !== `root`)
            .filter(key => actionKeys.includes(key) === false)
            .filter(key => valueKeys.includes(key) === false)
            .filter(key => derivedKeys.includes(key) === false)
            .filter(key => state[key] && typeof state[key].toJSON === `function`);

        const entries = [
            ...Object.keys(values).map(key => [key, state[key]]),
            ...subValues.map(key => [key, state[key].toJSON()]),
            ...staticKeys.map(key => [key, state[key]]),
        ];

        return Object.fromEntries(entries);
    }
}

function deepSubscribe({ state, name, handlers, separator = NAME_SEPARATOR }) {
    const [current, subName] = name.split(separator);
    const obsName = buildObserverName(current);

    // The last one doesn't require special handling
    if (subName === undefined) {
        return state[obsName].subscribe(handlers);
    }

    let subSubscription = subSubscribe(state[current]);

    const next = typeof handlers === `function` ? handlers : handlers.next;
    assert(typeof next === `function`, `handlers or handlers.next MUST be a function`);

    // Whenever the current value changes the sub subscription
    //  needs to
    // TODO: Only the first level should resepct complete
    state[obsName].subscribe({
        complete: () => handlers.complete && handlers.complete(),
        error: error => handlers.error && handlers.error(error),
        next: value => {
            subSubscription.unsubscribe();
            // Re-subscribe to the new one
            subSubscription = subSubscribe(value);

            // The value may have changed
            const subVal = getSubValue(value, subName);
            return next(subVal);
        },
    });

    let isClosed = false;
    const subscription = {};
    Object.defineProperties(subscription, {
        unsubscribe: {
            configurable: true,
            enumerable: true,
            writable: true,
            value: unsubscribe,
        },
        closed: {
            configurable: true,
            enumerable: true,
            get: () => isClosed || subSubscription.closed,
        },
    });
    return subscription;

    function unsubscribe() {
        isClosed = true;
        subSubscription.unsubscribe();
        subSubscription = undefined;
    }

    function subSubscribe(state) {
        return deepSubscribe({
            state,
            name: subName,
            handlers,
            separator,
        });
    }
}

function hasAllDependencies({ state, dependencies }) {
    // We just check for the observable
    return dependencies.every(dependency => hasDependency({ state, dependency }));
}

function hasDependency({ state, dependency, separator = `.` }) {
    // TODO: Change to regex split
    assert(state, `state MUST be supplied`);
    if (typeof dependency === `string`) {
        return hasDependency({ state, dependency: dependency.split(separator) });
    }
    assert(Array.isArray(dependency), `dependency MUST be a string`);

    const current = buildPlainName(dependency.shift());
    if (!state[buildObserverName(current)]) {
        return false;
    }

    if (dependency.length === 0) {
        return true;
    }

    const subState = state[current];
    if (!subState) {
        return false;
    }

    return hasDependency({ state: subState, dependency, separator });
}

async function defaultCombine(state, ...depndencies) {
    // By default create an object with all the dependant properties attached
    const entries = await Promise.all(
        depndencies.map(dependency => [dependency, state[dependency]])
    );
    return Object.fromEntries(entries);
}

function buildUseName(key) {
    if (key.length === 0) {
        return `use`;
    }
    key = `${key[0].toUpperCase()}${key.substr(1)}`;
    return `use${key}`;
}

function buildPlainName(value) {
    if (value.endsWith(`$`)) {
        return value.substr(0, value.length - 1);
    } else {
        return value;
    }
}

function buildObserverName(value) {
    if (value.endsWith(`$`)) {
        return value;
    } else {
        return `${value}$`;
    }
}

function getSubValue(object, name) {
    if (!object || typeof object !== `object`) {
        return undefined;
    }
    const [current, subName] = name.split(NAME_SEPARATOR);

    if (subName === undefined) {
        return object[current];
    } else {
        return getSubValue(object[current], subName);
    }
}

function noop() {
    // Does nothing
}

function identity(value) {
    return value;
}

function equality(a, b) {
    return a === b;
}

function defineReadOnlyProperty(state, name, value) {
    Object.defineProperty(state, name, {
        configurable: true,
        enumerable: true,
        writable: false,
        value,
    });
}
