import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import createStateLink from './link-state.js';
import createArgonathLink from './link-argonath.js';
import createAuthLink from './link-auth.js';

const RETRY = 10;
const DELAY = 100;
const MAX_DELAY = 10000;

const clientCache = new WeakMap();
const authClientCache = new WeakMap();

export function getClient(state) {
    return getCached(clientCache, state, createClient);
}

export function getAuthenticatedClient(state) {
    return getCached(authClientCache, state, createAuthenticatedClient);
}

function getCached(cache, state, create) {
    if (!cache.has(state)) {
        cache.set(state, create(state));
    }
    return cache.get(state);
}

function createClient(state) {
    return createBaseClient(state);
}

function createAuthenticatedClient(state) {
    const authLink = createAuthLink();
    const client = createBaseClient(state, [authLink]);

    return client;
}

function createBaseClient(state, links = []) {
    const httpLink = new HttpLink({
        uri: `https://${state.root.$site}.team18media.app/graphql`,
    });

    const retryLink = new RetryLink({
        delay: {
            initial: DELAY,
            max: MAX_DELAY,
        },
        attempts: {
            max: RETRY,
            retryIf: error => {
                console.log(`retryIf`, error);
                return !!error;
            },
        },
    });

    const stateLink = createStateLink(state);
    const argonathLink = createArgonathLink();

    // TODO: On the server this should
    const isServer = typeof window === `undefined`;

    // TODO: How could we tie this up to the asset loaders?
    //  If we know what is cached we can get it from the cache
    //  instead of from the batch...
    const cache = new InMemoryCache({
        // TODO: Configure good caching
        resultCaching: !isServer,

        typePolicies: {
            Query: {
                fields: {
                    asset: {
                        merge: true,
                    },
                    talent: {
                        merge: true,
                    },
                    video: {
                        merge: true,
                    },
                },
            },
            Asset: {
                keyArgs: `path`,
            },
        },

        invalidationPolicies: {
            timeToLive: isServer ? 0 : 50 * 60 * 1000,
            renewalPolicy: `WriteOnly`,

            types: {
                AssetBatchSearchResult: {
                    timeToLive: isServer ? 0 : 5 * 60 * 1000,
                },
                TalentListResult: {
                    timeToLive: isServer ? 0 : 5 * 60 * 1000,
                },
            },
        },
    });

    return new ApolloClient({
        link: from([stateLink, ...links, argonathLink, retryLink, httpLink]),
        cache,

        // TODO: From config
        name: `${state.root.$site}:site`,
        version: `1.0`,

        // TODO: How to set this (server side rendering mode)
        //  dynamically?
        ssrMode: false,
    });
}
