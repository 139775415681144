import React from 'react';
export default function PageStaticTerms({ state }) {
    const site = `${state.root.$site.toUpperCase()}.com`;
    const mailTo = `mailto:${state.root.email.$contact}`;
    return (
        <div className="page">
            <h1>Terms &amp; Conditions</h1>
            <p>
                Before Subscriber's transaction can be completed, Subscriber must read and agree to
                these terms and conditions, "Agreement". By applying for access and / or services
                from this website, Subscriber is agreeing to these terms and conditions, and is
                agreeing to be legally bound by them. This Agreement is subject to change at any
                time. Changes are effective when posted on this site without notice to each
                subscriber.
            </p>
            <h3>PREAMBLE</h3>
            <p>
                Subscriber's credit card will be billed immediately after purchase. After purchase
                Subscriber will receive an email notification with all payment details. The contract
                is closed between Subscriber and Site as soon as the order is submitted. All
                questions will be answered within six working days. Any motion pictures,
                photographs, or other content found on the Site and any subscription to the Site is
                expressly prohibited for anyone under legal age in their respective
                country/jurisdiction, and as a minimum to anyone below 18 years of age.
            </p>

            <h3>DEFINITIONS</h3>
            <p>
                "Member" or "Membership," shall mean the subscriber or user of a valid username and
                password for the site during the term of membership.
                <br />"{site}" shall mean any of the companies billing the Subscriber including any
                additional billing companies used by {site} or changes thereof.
                <br />
                "Site" shall mean the website for which subscriber is purchasing a username and
                password in order to access the site and its materials and obtain the benefits of
                Membership.
                <br />
                "Subscriber" shall mean the user of the services of the site and holder of a valid
                username and password for the Site.
                <br />
                "Access Right," shall mean the combination of unique username and password that is
                used to access a site. An access right is a license to use a Site for a period of
                time that is specified.
            </p>

            <h3>DESCRIPTION OF SERVICES</h3>
            <p>
                {site} will provide one access right to access the Site and its materials for which
                Subscriber is purchasing a Membership.
            </p>

            <h3>BILLING</h3>
            <p>
                Billing is processed by one of our partners (Verotel). Subscriber will be informed
                at the time of subscribing what description will appear on Subscriber’s credit card,
                bank statement, or phone bill for all applicable charges. If multiple venues are
                joined utilizing any payment method, Subscriber’s statement will list each
                individual purchase comprising the transaction. Billing may include other
                information.
            </p>

            <h3>PAYMENT / FEE</h3>
            <p>
                The Sites may have periodic subscription fees from the time of the initial
                enrollment for the Membership . The Subscriber is responsible for such fees
                according to the terms and conditions of the Site.
            </p>

            <h3>AUTOMATIC RECURRING BILLING (if selected by subscriber during sign-up)</h3>
            <p>
                In accordance with the terms and conditions of the Site subscription fees may be
                automatically renewed at or after the end of the original term selected, for a
                similar period of time and for a similar or different amount, unless notice of
                cancellation is received from the Subscriber. Unless and until this agreement is
                cancelled in accordance with the terms hereof, Subscriber hereby authorizes {site}
                {` `}
                to charge Subscriber's chosen payment method to pay for the ongoing cost of
                membership. Subscriber hereby further authorizes {site} to charge Subscriber's
                chosen payment method for any and all additional purchases of materials provided on
                the site. In the event of an unsuccessful recurring payment, the Site will
                discontinue Subscriber's membership immediately.
            </p>

            <h3>ELECTRONIC RECEIPT</h3>
            <p>
                Subscriber will receive an email receipt to their email provided upon initial
                subscription. Subscriber may request a copy of the account of charges of their
                membership to the Site but
                {site} does not guarantee the availability of such records more than 365 days after
                a charge. Requests must be made directly to {site}. To contact {site} refer to
                Customer Support links on the Site.
            </p>

            <h3>CANCELLATION</h3>
            <p>
                At any time, and without cause, subscription to the service may be terminated by
                either:
                {site}, the Site, or the Subscriber upon notification of the other by electronic or
                conventional mail, by chat, or by telephone. Subscribers are liable for charges
                incurred until the date of the termination. For billing inquiries or to cancel your
                membership, please visit Verotel.com, our authorized sales agent.
            </p>

            <h3>REFUNDS</h3>
            <p>
                Refunds for purchases or recurring charges may be requested by contacting customer
                support. Refunds or credits will not be issued for partially used Memberships.
                Cancellation for all current and future recurring billing may be requested as set
                forth in the above paragraph.
                {site} reserves the right to grant any refunds or credits at its discretion. The
                decision to refund a charge does not imply the obligation to issue additional future
                refunds. Should a refund be issued by {site} for any reason, it will be credited
                solely to the payment method used in the original transaction. {site} will not issue
                refunds by cash, check, or to another payment mechanism.
            </p>

            <h3>CARDHOLDER DISPUTES/CHARGEBACKS</h3>
            <p>
                We review all chargebacks carefully to ensure no party is incorrectly charged. We
                also reserve the right to deny future purchases to accounts with chargebacks that
                may lack merit depending on the circumstances. Claims of fraud are taken seriously
                and may result in the Site notifying Subscriber’s issuer to protect Subscriber and
                prevent future fraudulent charges to Subscriber’s card.
            </p>

            <h3>AUTHORIZATION OF USE</h3>
            <p>
                Subscribers to the Site are authorized to access the service or material located at
                this website through a single Access Right. This Access Right shall be granted for
                sole use to one Subscriber. All Memberships are provided for personal use only and
                shall not be used for any commercial purposes or by any other third parties.
                Commercial use of either the Site or any material found within it is strictly
                prohibited unless authorized by the Site. No material within the Site may be
                transferred to any other person or entity, whether commercial or non-commercial. No
                material within the Site may be distributed, downloaded, or otherwise obtained
                through peer-to-peer networks or any other file sharing platforms. In addition,
                materials may not be modified, or altered. Materials may not be displayed publicly,
                or used for any rental, sale, or display. Materials extend to copyright, trademarks,
                or other proprietary notices. {site}
                and the Site reserve the right to terminate the Access Right at any time for any
                Subscriber if the terms of this Agreement are breached. In the event that the terms
                are breached, Subscriber will be required to immediately destroy any information or
                material printed, downloaded or otherwise copied from the Site.
            </p>

            <h3>TRANSFER OF YOUR ACCESS RIGHT</h3>
            <p>
                Access to the Site - the Subscriber’s Access Right - is through a combination of a
                username and a password. Subscribers may not release their Access Right to any
                person, and are required to keep their Access Right confidential. {site} will not
                release a Subscriber’s password, to anyone other than the Subscriber, except as may
                be required by law or court order. Unauthorized access to the Site - including
                sharing your Access Right with others - is a breach of this Agreement. Subscribers
                acknowledge that the Site may track each Subscriber's entry to the site to prevent
                unauthorized access. In the event of a security breach, theft, or loss of a device
                containing the Access Right, resulting in potential unauthorized disclosure
                Subscriber must immediately notify {site} or the Site of said security breach.
            </p>

            <h3>SANCTION AND APPROVAL OF ADULT MATERIAL</h3>
            <p>
                This Site contains age-restricted materials. If Subscriber is under the age of 18
                years, or under the age of majority in the location from where accessing this Site
                Subscriber does not have authorization or permission to enter or access any of its
                materials. If Subscriber is over the age of 18 years and over the age of majority in
                the location from where accessing this site by entering the website Subscriber
                hereby agrees to comply with these terms and conditions.
            </p>

            <h3>SEVERABILITY</h3>
            <p>
                If any provision of this Agreement shall be held invalid or unenforceable for any
                reason, the remaining provisions shall continue to be valid and enforceable. If a
                court finds that any portion of this Agreement is invalid or unenforceable, but that
                by limiting such provision it would become valid or enforceable, then such provision
                shall be deemed to be written, construed, and enforced as so limited.
            </p>

            <h3>NOTICE</h3>
            <p>
                Notices by the Site to Subscribers may be given by means of electronic messages
                through the Site, by a general posting on the Site, or by conventional mail or
                email. Notices by Subscribers may be given by email, conventional mail, telephone or
                fax unless otherwise specified in the Agreement. All questions, complaints, or
                notices regarding the site must be directed to {site}, <a href={mailTo}>here</a>
            </p>

            <h3>DISCLAIMER</h3>
            <p>
                Subscriber understands that the Site cannot and does not guarantee or warrant that
                files available for downloading from the Internet will be free of viruses, worms,
                trojan horses or other code that may manifest contaminating or destructive
                properties. Subscriber is responsible for implementing sufficient procedures and
                checkpoints to satisfy Subscriber’s particular requirements for accuracy of data
                input and output, and for maintaining a means external to the site for the
                reconstruction of any lost data. Site does not assume any responsibility or risk for
                Subscriber’s use of the Internet. However, in the event Site learns of such a
                breach, Site will notify Subscriber so that Subscriber can take the necessary
                precautions.
            </p>
            <p>
                Subscriber’s use of the Site is at his or her own risk and the content is provided
                “as is” - without warranties of any kind, either expressed or implied. Site
                disclaims all warranties including any implied warranties of merchantability,
                fitness for a particular purpose, title or non-infringement. Site does not warrant
                that the functions or content contained in the Site will be uninterrupted or
                error-free, that defects will be corrected, or that the Site or the server that
                makes it available are free of viruses or other harmful components.
            </p>
            <p>
                The Site does not warrant or make any representation regarding use, or the result of
                use, of the content in terms of accuracy, reliability, or otherwise. The content may
                include technical inaccuracies or typographical errors, and the Site may make
                changes or improvements at any time. The Site does not warrant or make any
                representations regarding the appropriateness of the material or content or the
                authorization for use in all countries, states, provinces, county or any other
                jurisdictions. If Subscriber chooses to access the Site, Subscriber does so at its
                own risk, and subject to the laws in its own jurisdiction. Subscriber is responsible
                for compliance with all applicable laws.
            </p>

            <h3>SUBSCRIPTION FEES AND SUBSCRIBER’S COMMUNICATION</h3>
            <p>
                Subscription and Membership fees to Site are subject to change at any time at the
                sole and absolute discretion of {site}. The official standard membership rates for
                the Site shall be set forth at the following link: {site}. The current monthly
                membership rate which will appear on Subscriber’s credit card bill, will be debited
                from Subscriber’s account, charged to Subscriber’s telephone, etc., depending on
                Subscriber’s choice of payment means.
            </p>
        </div>
    );
}
