const constants = {
    color: {
        primary: {
            r: 225,
            g: 13,
            b: 14,
        },
    },
    size: {
        // All shown widths assume a base font size of 16px
        massive: `120em`, // 1920px
        large: `90em`, // 1440px
        standard: `64em`, // 1024px
        medium: `50em`, // 800px
        mobile: `48em`, // 768px
        small: `40em`, // 640px
        tiny: `20em`, // 320px
    },
};

export default constants;
