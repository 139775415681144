import React from 'react';
import styled from 'styled-components';
import Navigate from '../container/navigate.jsx';
import CardImageInfo from '../component/card/image-info.jsx';
import CardImageTitled from '../component/card/image-titled.jsx';
import Cards from '../container/cards.jsx';

const StyledPage = styled.div`
    position: relative;

    h1 {
        text-align: center;
    }

    .page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
`;

export default function PageModel({ state }) {
    const model = state.useModel();
    const modelInfo = [
        { name: `Height`, value: unit(model.dimensions.height, `cm`) },
        { name: `Weight`, value: unit(model.dimensions.weight, `kg`) },
        {
            name: `Measurements`,
            value: measurements(model.dimensions.measurements),
        },
    ];

    return (
        <StyledPage>
            <CardImageInfo title={model.name} source={model.image} info={modelInfo} />
            {model.videos.length > 0 && (
                <div className="page">
                    <h1 className="heading">Videos</h1>
                    <Cards>
                        {model.videos.map(video => (
                            <Navigate
                                state={state.root}
                                key={video.videoId}
                                href={`/videos/${video.videoId}`}
                                disabled={video.loading}
                            >
                                <CardImageTitled
                                    title={video.title}
                                    image={video.image.landscape}
                                    blurTitle={video.loading}
                                />
                            </Navigate>
                        ))}
                    </Cards>
                </div>
            )}
        </StyledPage>
    );

    function unit(value, scale) {
        if (value > 0 === false) {
            return value;
        } else {
            return `${value}${scale}`;
        }
    }

    function measurements(value) {
        if (value && typeof value === `object`) {
            return [
                model.dimensions.measurements.cup,
                model.dimensions.measurements.waist,
                model.dimensions.measurements.hips,
            ].join(` - `);
        } else {
            return String(value);
        }
    }
}
