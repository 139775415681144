import React, { useEffect } from 'react';
import styled from 'styled-components';
import dummyAd from '../artifacts/300x250-viviantaylor1.jpg';

const Title = styled.h2`
    text-transform: uppercase;
    font-size: 1.25em;
    margin: 0.25em;
    margin-top: 1em;
    text-align: center;
`;

const StyledDiscounts = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: 100%;

    div {
        display: flex;
        justify-content: center;
    }
`;

export function Discounts({ title, hide }) {
    const isServerSide = typeof window === `undefined`;
    const insertDummyAd =
        !isServerSide &&
        (window.location.origin.includes(`localhost`) || window.location.origin.includes(`test.`));

    useEffect(() => {
        if (!isServerSide && !hide && !insertDummyAd) {
            const script = document.createElement(`script`);

            script.text = `
            (function(ac) {
                var d = document, s = 'script', id = 'adplugg-adjs';
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id; js.async = 1;
                js.src = '//www.adplugg.com/apusers/serve/' + ac + '/js/1.1/ad.js';
                fjs.parentNode.insertBefore(js, fjs);
              }('A48214987'));`;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [hide, insertDummyAd, isServerSide]);

    return hide ? null : (
        <StyledDiscounts>
            {title && <Title>{title}</Title>}
            {insertDummyAd ? (
                <div>
                    <img src={dummyAd} alt="" width={300} height={250} />
                </div>
            ) : (
                <div className="adplugg-tag" data-adplugg-zone="footer_1_300x250"></div>
            )}
        </StyledDiscounts>
    );
}
