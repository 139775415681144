import { gql } from '@apollo/client';
import { getClient } from './client/index.js';
import handleError from './handle-error.js';

export default function createSearchService(state) {
    return {
        search,
    };

    function search(query) {
        return handleError(() => doSearch(query));
    }

    async function doSearch(query) {
        const client = await getClient(state);
        const result = await client.query({
            query: gql`
                query Search($query: String!) {
                    search {
                        search(input: { query: $query }) {
                            result {
                                type
                                itemId
                                name
                                description
                                images
                            }
                        }
                    }
                }
            `,
            variables: { query },
        });
        return result.data.search.search.result;
    }
}
