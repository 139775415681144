import React from 'react';
import HeaderComponent from '../component/header.jsx';

export default function Header({ user, hideMenu, state }) {
    return (
        <>
            <HeaderComponent
                onLogoClick={event => showPage(event, `/`)}
                onVideosClick={event => showPage(event, `/videos`)}
                onModelsClick={event => showPage(event, `/models`)}
                onJoinClick={event => showPage(event, `/join`)}
                isLoggedIn={Boolean(user)}
                hideMenu={hideMenu}
                state={state}
            />
        </>
    );

    function showPage(event, page) {
        event.preventDefault();
        state.page.navigate(page);
    }
}
