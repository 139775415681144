import createAssetService from '../../argonath/asset.js';
import createTalentService from '../../argonath/talent.js';
import promiseTree from '../../../lib/promise-tree.js';

import createState from '../state.js';
import placeholder from '../../../lib/placeholder.js';

const LOADING = {
    name: ``,
    dimensions: {
        height: `FITNESS`,
        weight: `IS`,
        measurements: `SEXY`,
    },
    image: {
        portraight: {
            x1: placeholder(4, 5),
        },
        landscape: {
            x1: placeholder(16, 9),
        },
    },
    videos: [],
};

export default function createModelState({ root, values: { model, modelId = `` } = {} }) {
    const loadingModel = {
        ...LOADING,
        name: modelId.split(`-`).join(` `),
        // The id needs to match for pathing
        modelId,
    };
    model = model || loadingModel;
    const { serve } = createAssetService(root);
    const { find: findTalent } = createTalentService(root);

    const state = createState({
        root,

        values: {
            $page: `model`,
            title: ``,
            model,
        },

        derived: {
            title: {
                from: [`model`],
                combine: state => state.model.name,
            },
        },

        createActions,
    });

    return state;

    function createActions() {
        return {
            load,
        };

        async function load() {
            if (state.model !== loadingModel) {
                return;
            }

            if (!modelId) {
                root.page.notFound();
                return;
            }

            const model = await findTalent(modelId);
            if (!model) {
                root.page.notFound();
                return;
            }

            // TODO: Separate profile and video load
            const prepared = await prepareModel(model);
            state.model = prepared;
        }
    }

    function prepareModel(talent) {
        if (!talent) {
            return talent;
        }
        const { talentId, videos, ...rest } = talent;
        return promiseTree({
            modelId: talentId,
            ...rest,
            image: {
                portraight: {
                    x1: serveImage(`/members/models/${talentId}/profile-sm.jpg`),
                },
                landscape: {
                    x1: serveImage(`/members/models/${talentId}/profile-big.jpg`),
                },
            },
            videos:
                videos &&
                videos.map(video => {
                    const [, sceneId] = video.videoId.split(`:`);
                    return {
                        ...video,
                        image: {
                            portraight: {
                                x1: serveImage(
                                    `/members/models/${talentId}/scenes/${sceneId}/selfie-sm.jpg`
                                ),
                            },
                            landscape: {
                                x1: serveImage(
                                    `/members/models/${talentId}/scenes/${sceneId}/videothumb.jpg`
                                ),
                            },
                        },
                    };
                }),
        });
    }

    async function serveImage(path) {
        if (!path) {
            return undefined;
        }
        const info = await serve(path);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return undefined;
        }
        return info && info.serve && info.serve.uri;
    }
}
