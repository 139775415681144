import assert from 'assert';
import uuid from 'uuid';
import createState from './state.js';

export default function createInteractionState({ root }) {
    const running = {};
    let count = 0;

    return createState({
        root,
        values: {
            busy: false,
        },
        createActions,
    });

    function createActions(state) {
        return {
            start,
            end,
        };

        function start(id = uuid.v4()) {
            assert(!running[id], `${id} has already been registered as running`);
            running[id] = true;
            count++;

            if (count === 1) {
                process.nextTick(() => {
                    if (count >= 1) {
                        state.busy = true;
                    }
                });
            }

            return id;
        }

        function end(id) {
            if (running[id]) {
                delete running[id];
                count--;

                if (count === 0) {
                    process.nextTick(() => {
                        if (count === 0) {
                            state.busy = false;
                        }
                    });
                }
            }
        }
    }
}
