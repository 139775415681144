import React from 'react';
import Currency from './currency/index.jsx';

export default function Money({ Large, Small, currency, amount }) {
    const small = amount % 100;
    const large = (amount - small) / 100;
    return (
        <Currency symbol={currency}>
            <Large>{large}</Large>
            <Small>{small}</Small>
        </Currency>
    );
}
