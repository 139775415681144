import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Masonry from '../container/masonry.jsx';
import Navigate from '../container/navigate.jsx';
import Pager from '../component/element/pager.jsx';
import OurStory from '../component/our-story.jsx';
import { JoinButton } from '../component/button/join.jsx';
import { Banner } from '../component/banner.jsx';

const MAX_IMAGES = 7;

const PLAYER_STYLE = {
    width: `100%`,
    display: `block`,
};

const StyledPage = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .center {
        text-align: center;
    }
`;

export default function PageTour({ state }) {
    const masonry = state.useMasonry();
    const special = state.useSpecial();
    const specialLink = state.useSpecialLink();

    return (
        <>
            <Banner state={state} special={special} specialLink={specialLink} />
            <StyledPage className="page">
                <div className="call-to-action center">
                    <h2 className="heading">{state.root.$topic}</h2>
                    <JoinButton>Join To Watch</JoinButton>
                </div>
                <Masonry columns={3} state={state.root}>
                    {masonry.map((item, index) => {
                        return (
                            <MasonryElement
                                state={state}
                                key={index}
                                file={item.file}
                                join={item.join}
                                index={index}
                            />
                        );
                    })}
                </Masonry>
                <Pager pages={4} pageUri={() => `/join`} />
                <OurStory state={state} />
                <JoinButton>Join Now</JoinButton>
            </StyledPage>
        </>
    );
}

function MasonryElement({ file, join, poster, state, index }) {
    const style = {
        margin: `0.5em`,
    };
    return (
        <Navigate
            state={state.root}
            href={`/join${join ? `?pic=${encodeURIComponent(join)}` : ``}`}
        >
            <div style={style}>
                <MasonryElementFile file={file} poster={poster} index={index} />
            </div>
        </Navigate>
    );
}

function MasonryElementFile({ file, poster, index }) {
    if (file?.type === `IMAGE`) {
        if (index < MAX_IMAGES) {
            return (
                <img
                    src={file.file} // use normal <img> attributes as props
                    width="100%"
                />
            );
        } else {
            return (
                <LazyLoadImage
                    src={file.file} // use normal <LazyLoadImage> attributes as props
                    width="100%"
                />
            );
        }
    } else if (file?.type === `VIDEO`) {
        if (poster) {
            poster = poster.file;
        }
        return (
            <video loop autoPlay muted poster={poster} style={PLAYER_STYLE}>
                <source src={file.file} type={file.mime} />
            </video>
        );
    } else {
        console.warn(`Unknown masonry element file type "${file?.type}" received`);
        return null;
    }
}
