import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Masonry from './masonry.jsx';
import Navigate from './navigate.jsx';

const MAX_IMAGES = 7;
const PRELOAD_DELAY = 100;

export default function Gallery({ state, ...props }) {
    const preloadImages = [];
    let preLoadProcessing;
    const gallery = state.useGallery();
    const photoIndex = state.usePhotoIndex();

    useEffect(() => {
        if (gallery && photoIndex > -1 && gallery.length && gallery[0].full) {
            preLoadImages([
                gallery[boundedPhotoIndex(photoIndex + 1)].full,
                gallery[boundedPhotoIndex(photoIndex + 2)].full,
                gallery[boundedPhotoIndex(photoIndex - 1)].full,
                gallery[boundedPhotoIndex(photoIndex - 2)].full,
                gallery[boundedPhotoIndex(photoIndex + 3)].full,
                gallery[boundedPhotoIndex(photoIndex + 4)].full,
            ]);
        } else if (gallery && photoIndex === -1 && gallery.length && gallery[0].full) {
            preLoadImages(gallery.slice(0, 6).map(item => item.full));
        }
    }, [gallery, photoIndex]);

    if (!gallery || gallery.length === 0) {
        return null;
    }

    const masonryStyle = {
        width: `100%`,
    };

    return (
        <>
            <Masonry style={masonryStyle} state={state.root} {...props}>
                {gallery.map((item, index) => (
                    <div key={index}>
                        <div className="gallery-image">
                            {index < MAX_IMAGES && (
                                <img
                                    alt={`${state.video?.title}: Gallery #${index + 1}`}
                                    src={item.thumb}
                                    width="100%"
                                    onMouseOver={() => onMouseOverThumb(item.full)}
                                />
                            )}
                            {index >= MAX_IMAGES && (
                                <LazyLoadImage
                                    alt={`${state.video?.title}: Gallery #${index + 1}`}
                                    src={item.thumb}
                                    width="100%"
                                    onMouseOver={() => onMouseOverThumb(item.full)}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </Masonry>
            {photoIndex > -1 && (
                <div id="photo">
                    <div className="overlay" onClick={event => hidePhoto(event)} />
                    <div className="image">
                        <div id="photo-image">
                            <ImageGallery
                                lazyLoad={true}
                                showNav={true}
                                showThumbnails={false}
                                showPlayButton={false}
                                showFullscreenButton={false} // TODO: set to true when fixed
                                items={gallery.map(({ full: original }) => ({ original }))}
                                onSlide={index => {
                                    state.photoIndex = (index + gallery.length) % gallery.length;
                                }}
                                startIndex={photoIndex}
                            />
                        </div>
                        <a href="#" className="close-button" onClick={event => hidePhoto(event)}>
                            X
                        </a>
                    </div>
                </div>
            )}
        </>
    );

    function onMouseOverThumb(full) {
        if (full) {
            preLoadImages([full], true);
        }
    }

    function showPhoto(event, index) {
        event.preventDefault();
        state.photoIndex = (index + gallery.length) % gallery.length;
    }

    function hidePhoto(event) {
        event.preventDefault();
        state.photoIndex = -1;
    }

    function preLoadImages(images, hasPriority) {
        if (hasPriority) {
            preloadImages.unshift(...images.filter(Boolean));
        } else {
            preloadImages.push(...images.filter(Boolean));
        }
        if (!preLoadProcessing) {
            preLoadProcessing = setTimeout(process, PRELOAD_DELAY);
        }

        function process() {
            preLoadProcessing = false;
            if (preloadImages.length === 0) {
                return;
            }
            const image = new Image();
            image.onload = () => process();
            image.onerror = () => process();
            image.src = preloadImages.shift();
        }
    }

    function boundedPhotoIndex(index) {
        return (index + gallery.length) % gallery.length;
    }
}
