import createAssetService from '../../argonath/asset.js';

import createState from '../state.js';
import placeholder from '../../../lib/placeholder.js';

const DEFAULT_PICTURE = { x1: placeholder(3, 5) };
const DEFAULT_PICTURES = process.env.JOIN_IMAGES;

export default function createJoinState({ root, values: { sourcePicture } = {} }) {
    const { serve } = createAssetService(root);

    const state = createState({
        root,
        values: {
            $page: `join`,
            sourcePicture,
            title: ``,
        },
        derived: {
            picture: {
                from: [`sourcePicture`],
                combine: buildPicture,
                value: DEFAULT_PICTURE,
            },
        },
    });

    return state;

    async function buildPicture(state) {
        const pic =
            state.sourcePicture ||
            DEFAULT_PICTURES[Math.floor(Math.random() * DEFAULT_PICTURES.length)];

        const asset = `/graphics/site/join/model/${pic}`;
        const uri = await serveImage(asset);
        return {
            x1: uri,
        };
    }

    async function serveImage(path) {
        if (!path) {
            return undefined;
        }
        const info = await serve(path);
        if (!info) {
            console.warn(`Unable to find asset info for "${path}"`);
            return undefined;
        }
        return info && info.serve && info.serve.uri;
    }
}
