import React from 'react';
import assert from 'assert';

export default function Navigate(props) {
    const { href, onClick, state, disabled, ...rest } = props;
    assert(href, `href MUST be supplied`);
    assert(state, `state MUST be supplied`);

    return (
        <a href={href} onClick={onClickHandler} {...rest}>
            {props.children}
        </a>
    );

    function onClickHandler(event) {
        if (disabled) {
            return;
        }

        if (onClick) {
            onClick(event);
        } else if (href.startsWith(`/`)) {
            event.preventDefault();
            state.root.page.navigate(href);
        }
    }
}
