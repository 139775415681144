import React from 'react';
import styled from 'styled-components';
import constant from '../../constants.js';

const StyledSlide = styled.div`
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .badge {
        position: absolute;
        left: 2%;
        top: 5%;
        padding: 0.75em;
        text-transform: uppercase;
    }

    .badge > h3 {
        margin: 0;
        font-size: 1em;
        font-weight: bold;
    }

    .ribbon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }

    .ribbon > .info {
        color: var(--color-action-text);
    }

    @media all and (max-width: ${constant.size.medium}) and (max-aspect-ratio: 1 / 1) {
        .ribbon {
            display: flex;
            flex-direction: column;
        }

        .ribbon > .background {
            position: relative;
            flex-grow: 1;
            opacity: 0;
        }

        .ribbon > .info {
            font-size: 1.25em;
            flex-grow: 0;
            text-shadow: 2px 2px 3px var(--color-tertiary-text);
            text-align: center;
        }
    }

    @media not all and (max-width: ${constant.size.medium}) and (max-aspect-ratio: 1 / 1) {
        .ribbon {
            display: block;
        }

        .ribbon > .background {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 27.5%;
            transform: skewX(-10deg);
            transform-origin: left bottom;
            opacity: 0.8;
        }

        .ribbon > .info {
            position: absolute;
            left: 7%;
            top: 15%;
            bottom: 0;
            width: 22%;
            word-wrap: break-word;
        }
    }

    @media (max-width: ${constant.size.small}) {
        .ribbon {
            font-size: 0.8em;
        }

        .badge {
            font-size: 0.8em;
        }
    }
`;
const StyledPicture = styled.picture`
    img {
        display: block;
        width: 100%;
    }
`;

export default function SlideInfo(props) {
    const { size, title, info, type, source, Action, ...rest } = props;
    return (
        <StyledSlide className="slide info border" {...rest}>
            <div className="image">
                <StyledPicture>
                    <source srcSet={source.landscape.x1} media={`(min-width: ${size.medium})`} />
                    <source srcSet={source.landscape.x1} media={`(min-aspect-ratio: 1 / 1)`} />
                    <source srcSet={source.portraight.x1} />
                    <img src={source.landscape.x1} alt={title} />
                </StyledPicture>
            </div>
            <div className="ribbon">
                <div className="background action"></div>
                <div className="info">
                    <h1>
                        {title} {info && `- `}
                        {info}
                    </h1>
                    <div>{Action && <Action />}</div>
                </div>
            </div>
            <div className="badge">
                <h3>{type}</h3>
            </div>
        </StyledSlide>
    );
}
