import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Dialog from './dialog.jsx';

const Input = styled.input`
    margin: auto;
    margin-bottom: 5px;

    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    padding: 0.5rem;
    border: 1px solid #cacaca;
    border-radius: 0;
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
    color: #0a0a0a;
    appearance: none;

    overflow: visible;

    :focus-visible {
        outline: 0px;
    }
`;

const Button = styled.button`
    display: block;
    margin: auto;
    margin-bottom: 5px;
    width: 100%;
    background-color: var(--color-action);
    color: var(--color-primary);
    font-weight: bold;
    border: 1px solid transparent;
    padding: 0.85em 1em;

    &:hover {
        background-color: var(--color-action-button);
    }
`;

function ResetPasswordDialog({ state }) {
    const [error, setError] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);

    return (
        <Dialog name="reset" onClose={() => setSubmitted(false)}>
            <h1>FORGOT / RESET YOUR PASSWORD?</h1>
            {submitted ? (
                <p>Please check your email including your spam folder</p>
            ) : (
                <>
                    <p>Enter your email to reset your password</p>
                    <form onSubmit={onSubmit}>
                        <Input
                            type="email"
                            placeholder="email"
                            name="email"
                            required
                            autoComplete="email"
                        />
                        {error && <div className="error">{error}</div>}
                        <Button type="submit">RESET</Button>
                    </form>
                </>
            )}
        </Dialog>
    );

    async function onSubmit(event) {
        event.preventDefault();

        const interactionId = state.root.interaction.start();
        try {
            setError(undefined);
            const email = event.target.elements.email.value;

            const response = await fetch(
                `${state.root.key.api}/api/legacy/user/reset-password?site=${state.root.$site}`,
                {
                    method: `post`,
                    headers: {
                        Accept: `application/json`,
                        'Content-Type': `application/json`,
                    },
                    body: JSON.stringify({
                        email,
                    }),
                }
            );

            if (response.ok) {
                const { success } = await response.json();
                if (success) {
                    setSubmitted(true);
                } else {
                    setError(`Email not found`);
                }
            }
        } catch (error) {
            console.error(`Error while submitting reset password`, error);
            setError(`Email not found`);
        } finally {
            state.root.interaction.end(interactionId);
        }
    }
}

export default ResetPasswordDialog;
