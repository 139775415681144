import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
    position: relative;
    padding: 1em;
    margin: 0;

    h2 {
        text-transform: uppercase;
        text-align: center;
        margin-top: 0;
    }
`;

const DownloadButton = styled.button`
    display: block;
    width: calc(100% - 0.4em);
    box-sizing: border-box;
    margin: 0.2em;
    padding: 0.5em;
    font-size: 1em;
    border: 0;
    cursor: pointer;
    font-weight: 600;
`;

export default function CardDownload({ state, downs, onDownloadClick = onDownloadClickDefault }) {
    const downloads = downs || state.useDownloads();
    if (!downloads || downloads.length === 0) {
        return null;
    }

    const isLoading = downloads.every(download => download.uri === false);
    const extra = isLoading ? [`blur`] : [];
    const className = (...existing) => [...existing, ...extra].join(` `);
    return (
        <StyledCard className="card border half">
            <h2 className={className()}>Downloads</h2>
            {downloads.map(download => {
                const uri = download.uri === false ? undefined : download.uri || `#lockdown`;
                return (
                    <a
                        key={download.title}
                        href={uri}
                        onClick={event => {
                            if (uri) {
                                onDownloadClick(event, download.uri);
                            }
                        }}
                    >
                        <DownloadButton className={className(`action`)}>
                            {download.title}
                        </DownloadButton>
                    </a>
                );
            })}
        </StyledCard>
    );
}

function onDownloadClickDefault() {
    // Do nothing so the anchor follows the standard href
}
