// TODO: Should this not be in a service?
export const TRACK_PREFIX = `trk-`;

export function setTracking() {
    if (typeof window === `undefined`) {
        return;
    }
    const uri = new URL(window.location.href);
    for (let entry of uri.searchParams.entries()) {
        if (entry[0].startsWith(TRACK_PREFIX)) {
            window.localStorage.setItem(
                entry[0],
                JSON.stringify({
                    when: Date.now(),
                    value: entry[1],
                })
            );
        }
    }
}

export function getTracking() {
    const result = {};
    if (typeof window === `undefined`) {
        return result;
    }
    for (let index = 0; index < window.localStorage.length; index++) {
        let key = window.localStorage.key(index);
        if (!key.startsWith(TRACK_PREFIX)) {
            continue;
        }

        let source;
        try {
            source = window.localStorage.getItem(key);
        } catch (error) {
            console.error(`Error reading tracking value "${key}" out of localStorage`, error);
            continue;
        }

        let value;
        try {
            value = JSON.parse(source);
        } catch (error) {
            console.error(`Error parsing tracking value "${key}" with data: ${source}`, error);
            continue;
        }
        key = key.substr(TRACK_PREFIX.length);
        result[key] = value;
    }
    return result;
}
