import React from 'react';
import styled from 'styled-components';

// TODO: import vars
const StyledPager = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
        font-size: 0.9em;
        margin: 0.75em;
        padding: 0.75em;
        font-weight: 400;
        transition: background-color 0.2s;
        border-radius: 2px;
    }

    div.current {
        color: var(--color-action-text);
        background-color: var(--color-action);
    }

    div:not(.current):hover {
        background-color: rgba(var(--color-primary-text-rgb), 0.2);
        cursor: pointer;
    }

    .pager-next:after {
        content: '»';
        margin-left: 1em;
    }
`;

export default function Pager({ pages, pageUri }) {
    const subsequent = [];
    for (let index = 1; index < pages; index++) {
        subsequent.push(
            <div key={index}>
                <a href={pageUri(index)}>{index + 1}</a>
            </div>
        );
    }
    return (
        <StyledPager>
            <div className="current">1</div>
            {subsequent}
            <div className="pager-next">
                <a href="/join">Next</a>
            </div>
        </StyledPager>
    );
}
