import assert from 'assert';
import createState from './state.js';
import createPackageService from '../argonath/package.js';
import createPurchaseService from '../argonath/purchase.js';
import mutex from './mutex.js';

const LOADING = {
    current: 2999,
    previous: 0,
    currency: `$`,
};

const VALID_BILLERS = [`verotel/flex-pay`, `verotel/rum`];

export default function createBillerState({
    root,
    values: { biller = VALID_BILLERS[0], packages } = {},
}) {
    const defaultPackages = Array(3)
        .fill(0)
        .map((_, index) => ({
            ...LOADING,
            code: `loading-${index}`,
            loading: true,
            description: root.$catchPhrase,
            default: index === 1,
        }));

    const { packages: availablePackages } = createPackageService(root);
    const { pay, upgrade: payUpgrade } = createPurchaseService(root);

    const state = createState({
        root,
        values: {
            biller,
            packages: packages || defaultPackages,
            error: undefined,
        },
        transform: {
            biller: value => {
                assert(
                    VALID_BILLERS.includes(biller),
                    `Biller "${value}" is invalid. Valid billers are: ${VALID_BILLERS.join(`, `)}`
                );
                return value;
            },
        },
        createActions,
    });
    return state;

    function createActions(state) {
        return {
            load: mutex(load),
            join,
            activate,
            reactivate,
            upgrade,
        };

        async function load() {
            try {
                const packages = await loadPackages();
                state.packages = packages;
            } catch (error) {
                // TODO: Retry?
                console.error(`Error loading packages`, error);
            }
        }

        async function loadPackages() {
            const packs = await availablePackages();
            const result = packs.map(pack => ({
                code: pack.code,
                currency: `$`,
                current: pack.current,
                previous: pack.previous,
                default: pack.default,

                description: {
                    main: pack.description,
                    sub: pack.sub,
                },
            }));
            return result;
        }
    }

    function upgrade({ package: pkg }) {
        return purchaseUpgrade({
            package: pkg,
            userId: state.root.user.user.sub,
        });
    }

    function join({ email, password, tracking, package: pkg }) {
        // TODO:Captcha
        return purchase({
            email,
            password,
            tracking,
            package: pkg,
            reactivation: false,
        });
    }

    function activate({ tracking, package: pkg }) {
        // TODO:Captcha
        return purchase({
            tracking,
            package: pkg,
            reactivation: false,
        });
    }

    function reactivate({ email, password, tracking, package: pkg }) {
        // TODO:Captcha
        return purchase({
            email,
            password,
            tracking,
            package: pkg,
            reactivation: true,
        });
    }

    async function purchase({ email, password, tracking, package: pkg, reactivation }) {
        // Get the biller enum name
        const billerEnum = billerName(state.biller);

        const interactionId = root.interaction.start();
        let result;
        try {
            result = await makePayment({
                email,
                password,
                tracking,
                reactivation,
                package: pkg,
                biller: billerEnum,
            });

            const label = reactivation
                ? `Reactivation of ${pkg} on ${billerEnum}`
                : `Purchase of ${pkg} on ${billerEnum}`;
        } finally {
            root.interaction.end(interactionId);
        }

        if (result) {
            // This will allow an autologin to happen later
            if (result.token) {
                root.user.joinToken = result.token;
            }
            processPurchaseAction(result);
        }
    }

    async function purchaseUpgrade({ package: pkg, userId }) {
        const billerEnum = billerName(state.biller);
        const interactionId = root.interaction.start();
        let result;
        try {
            state.error = undefined;
            result = await payUpgrade({
                userId,
                biller: billerEnum,
                package: pkg,
            });
        } catch (error) {
            console.error(`An error occurred while a user tried to upgrade`, error);
            state.error = {
                type: `generic`,
                message: `An error occurred while trying to upgrade. Please contact support`,
            };
        } finally {
            root.interaction.end(interactionId);
        }

        if (result) {
            state.root.user.forceRefresh = true;
            processPurchaseAction(result);
        }
    }

    async function processPurchaseAction(action) {
        switch (action.type) {
            case `REDIRECT`:
                // TODO: Do this through navigate service?
                window.location.assign(action.uri);
                break;
            case `CONFIRM`:
                // TODO: We need a confirmation dialog
                throw new Error(`not implemented`);
                break;
            case `COMPLETE`:
                // TODO: We need a message dialog
                console.warn(`TODO - Add message dialog to indicate payment complete`);
                break;
            default:
                console.error(`An unknown result type "${action.type}" was received.`);
                break;
        }
    }

    async function makePayment({ email, password, tracking, package: pkg, biller, reactivation }) {
        try {
            state.error = undefined;
            return await pay({
                email,
                biller,
                password,
                tracking,
                reactivation,
                package: pkg,
            });
        } catch (error) {
            // TODO: Figure out why this isn't coming back correctly
            if (error.message.match(/match the pattern/)) {
                error.code = `auth/invalid-password`;
            }
            switch (error.code) {
                case `auth/email-already-exists`:
                    state.error = {
                        type: `email`,
                        message: `The email address you entered is already in use`,
                    };
                    break;
                case `auth/email-inactive`:
                    state.error = {
                        type: `email`,
                        message: `The email address you entered is already in use. Please login to activate your account`,
                    };
                    break;
                case `auth/invalid-password`:
                    state.error = {
                        type: `password`,
                        // TODO: Get the correct code back
                        message: `The password you entered is invalid`,
                        // message: `The password you entered is invalid. ${error.message}`
                    };
                    break;
                default:
                    console.error(`An unknown error occurred while a user tried to join`, error);
                    state.error = {
                        type: `generic`,
                        message: `An error occurred while trying to join. Please contact support`,
                    };
                    break;
            }
        }
    }
}

function billerName(biller) {
    if (!biller) {
        return undefined;
    }
    switch (biller) {
        case `verotel/flex-pay`:
            return `VEROTEL_FLEX`;
        case `verotel/rum`:
            return `VEROTEL_RUM`;
        default:
            throw new Error(`Unknown biller name "${biller}" received`);
    }
}
